/**
 * Native lodash-like functions
 * Useful when working around you-dont-need-lodash-underscore eslint rule
 */

/**
 * Simple utility that returns new object with specified keys omitted from original object
 * Note: doesn't consider nested keys
 */
export const omit = (originalObj = {}, keysToOmit: string[]) => {
  return Object.fromEntries(Object.entries(originalObj).filter(([key]) => !keysToOmit.includes(key)));
};

/**
 * Simple utility that returns string with the first letter capitalized
 */
export const upperFirst = (s: string) => {
  if (!s) return s;
  return s[0].toUpperCase() + s.slice(1);
};
