import type { Extensions } from 'oas/extensions';
import type { OpenAPIV3 } from 'openapi-types';

/**
 * The token that separates the security scheme key and the UUID state in the `state` query parameter
 */
export const oauthStateSplitToken = '_rdmeoauthid_';

/**
 * OAuth2 flow grant types that we support.
 *
 * @see {@link https://swagger.io/docs/specification/authentication/oauth2/#flows}
 */
export const supportedOAuthFlows = {
  authorizationCode: 'Authorization Code',
  clientCredentials: 'Client Credentials',
} as const satisfies Record<keyof OAuthSchemeExtended['flows'], string>;

/**
 * OAuth2 flow grant types that we support.
 *
 * @see {@link https://swagger.io/docs/specification/authentication/oauth2/#flows}
 */
export type SupportedOAuthFlow = keyof typeof supportedOAuthFlows;

/**
 * The flow status values that are considered "completed".
 */
export const completedStatuses: OAuthTokenStatus['status'][] = ['done', 'failure', 'not-started'];

/**
 * The current status of the OAuth flow.
 */
type OAuthTokenStatus =
  | {
      /**
       * `not-started` means the flow has not been initiated (or it was reset).
       * `pending` means the user initiated the flow and the authorization popup window has yet to receive a response.
       */
      status: 'not-started' | 'pending';
    }
  | {
      /**
       * If `expires_in` exists, this is a slight rework of it so we can track the token's expiration date
       */
      expires_at?: number;

      /**
       * The raw access token response from the authorization server.
       *
       * @see {@link https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/}
       */
      response: {
        /**
         * The access token string as issued by the authorization server.
         */
        access_token: string;

        /**
         * If the access token expires, the server should reply with
         * the duration of time the access token is granted for.
         */
        expires_in?: number;

        /**
         * If the access token will expire, then it is useful to return a refresh token
         * which applications can use to obtain another access token.
         */
        refresh_token?: string;

        /**
         * Sometimes, the server will return a `scope` parameter that indicates the scopes that the token has access to.
         * If so, we'll parse it and store it in the `selectedScopes` array.
         *
         * @see {@link https://datatracker.ietf.org/doc/html/rfc6749#section-3.3}
         */
        scope?: string;
      };

      /**
       * `done` means the flow has completed successfully.
       * `received` means the authorization popup window has received a successful response from
       * the authorization server, but it hasn't been sent to the original window yet.
       */
      status: 'done' | 'received';
    }
  | {
      /**
       * If the OAuth flow fails, the authorization server will return an error. We clean this up and
       * surface it in the Auth component UI.
       *
       * @see {@link https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/#error}
       */
      error: string;

      /**
       * This either provides a short description of the error or the raw error response.
       * This is **not** surfaced in the UI.
       */
      error_description?: string;

      /**
       * `failure` means the flow has failed.
       */
      status: 'failure';
    };

/**
 * User-entered state for a given OAuth flow. These state variables are applicable
 * for the authorization code + client credentials grant types.
 *
 * @see {@link https://www.oauth.com/oauth2-servers/access-tokens/authorization-code-request/}
 * @see {@link https://www.oauth.com/oauth2-servers/access-tokens/client-credentials/}
 */
export type OAuthFlowClientState = OAuthTokenStatus & {
  /**
   * The client ID is a public identifier for apps. It is used to build authorization URLs and make requests to the token endpoint.
   *
   * @see {@link https://datatracker.ietf.org/doc/html/rfc6749#section-2.2}
   */
  clientId: string;

  /**
   * The client secret is a secret known only to the application and the authorization server.
   * It is used to authenticate to the token endpoint.
   *
   * @see {@link https://datatracker.ietf.org/doc/html/rfc6749#section-2.3.1}
   */
  clientSecret: string;

  /**
   * The URL to redirect the user to after they've authorized the client.
   *
   * This is only used in the authorization code grant type, but we store it here for simplicity
   * and don't use it in the client credentials grant type.
   *
   * @see {@link https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.1}
   */
  redirectUri: string;

  /**
   * The currently selected OAuth flow for the given security scheme.
   */
  selectedFlow: SupportedOAuthFlow;

  /**
   * The currently selected scopes
   */
  selectedScopes: string[];

  /**
   * The `state` parameter is used to prevent CSRF attacks. It is generated by the client and sent to the authorization server.
   * The authorization server sends the state back to the client, and the client verifies that the state parameter in the response.
   * If the state parameter in the response matches the state parameter that was sent, the client knows that the response is genuine.
   *
   * This is only used in the authorization code grant type, but we store it here for simplicity
   * and don't use it in the client credentials grant type.
   *
   * @see {@link https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.1}
   */
  state: string;
};

/**
 * All of the OAuth flow details for a given security scheme.
 */
export type OAuthSchemeExtended = Omit<OpenAPIV3.OAuth2SecurityScheme, 'flows' | 'type'> & {
  /**
   * The current state of the OAuth flow. Any in-progress changes will be saved to this object.
   */
  draftState: OAuthFlowClientState;

  /**
   * The final state of the OAuth flow. Once a token is received, it (along with any details such as client ID + scopes)
   * will be saved to this object.
   */
  finalState?: OAuthFlowClientState & { status: 'done' };

  /**
   * The OAuth flow details for the given security scheme as defined in the OpenAPI description.
   */
  flows: {
    authorizationCode?: NonNullable<OpenAPIV3.OAuth2SecurityScheme['flows']['authorizationCode']>;
    clientCredentials?: NonNullable<OpenAPIV3.OAuth2SecurityScheme['flows']['clientCredentials']>;
  };

  /**
   * Whether or not the proxy is enabled for the current operation.
   */
  proxyEnabled?: Extensions['proxy-enabled'];

  /**
   * The scopes required for the current operation.
   */
  requiredScopes?: OpenAPIV3.SecurityRequirementObject[string];

  /**
   * The separator used to delimit scopes in requests to the authorization server.
   * Defaults to a space, but can be configured via an OpenAPI extension.
   */
  scopeSeparator: NonNullable<Extensions['oauth-options']['scopeSeparator']>;

  /**
   * Whether or not the client should use insecure client authentication.
   * Defaults to `false` (i.e., secure authentication should be used), but can be configured via an OpenAPI extension.
   */
  useInsecureClientAuthentication: NonNullable<Extensions['oauth-options']['useInsecureClientAuthentication']>;
};
