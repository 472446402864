import type { IStringifyOptions } from 'qs';

import type { DateRangeOptions } from '@core/types/metrics';

function alphabeticalSort(a: string, b: string) {
  return a.localeCompare(b);
}
/** Standardized options for qs.stringify used on fetches */
export const stringifyOptions = {
  arrayFormat: 'brackets',
  skipNulls: true,
  sort: alphabeticalSort,
} as IStringifyOptions;

export const dateRangeDefaults = {
  day: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 24,
    resolution: 'hour',
    enabled: true,
  },
  week: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 7,
    resolution: 'day',
    enabled: false,
  },
  month: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 30,
    resolution: 'day',
    enabled: false,
  },
  quarter: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 12,
    resolution: 'week',
    enabled: false,
  },
  year: {
    rangeEnd: null,
    rangeStart: null,
    rangeLength: 12,
    resolution: 'month',
    enabled: false,
  },
} as DateRangeOptions;
