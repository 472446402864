import type { SuperHubStore } from '..';
import type { ReadRepresentationType } from '@readme/api/src/mappings/category/types';
import type { CreateGuideType, ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import { findSidebarFirstPage as findSidebarFirstPageISO } from '@readme/iso';
import produce from 'immer';
import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { actionLog, isClient } from '@core/store/util';

import type { ItemDropResult } from '@ui/Dash/PageNav/DragDrop';

import { findEntityByURI, getPageParentURI, getCategoryFromURI, SidebarMap, getTotalUnrenderable } from './util';

interface SuperHubSidebarSliceState {
  /**
   * Primary data source for our sidebar.
   */
  data: GitSidebarCategory[];

  /**
   * Contains information about any errors that occurred either during
   * hydration or updates.
   * @todo Still in progress, but we probably want to define our own dedicated
   * shape here as opposed to simply using one passed down from the API server.
   */
  error: unknown;

  /**
   * Whether sidebar data is currently being fetched via API.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized on both server and client. We use
   * this to differentiate how the state should update during SSR vs CSR. On the
   * server, the state should never prevent updates or retain previously
   * existing data. This is only safe to do once this flag is flipped to `true`.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Holds reference to the SWR request key that was used to fetch sidebar
   * data from the API. This key can then be used by SWR's `mutate()` function
   * to update cached data to optimistically reflect a changed sidebar while a
   * mutation request is in flight.
   * @link https://swr.vercel.app/docs/mutation
   * @example
   * ```ts
   * ['/cats/api-next/v2/versions/1.0.0/sidebar?page_type=guide', {}]
   * ```
   */
  swrKey: ReturnType<typeof useReadmeApi>['swrKey'];

  /**
   * Total number of pages in the sidebar that has `renderable.status` set to `false`.
   * These pages are unrenderable, typically due to invalid MDX syntax.
   */
  totalUnrenderable: number;
}

interface SuperHubSidebarSliceAction {
  /**
   * Creates a new category with the provided title.
   */
  createCategory: (title: string) => Promise<void>;

  /**
   * Deletes a category and removes it from the sidebar.
   */
  deleteCategory: (category: GitSidebarCategory) => Promise<void>;

  /**
   * Deletes a document and removes it from the sidebar.
   */
  deletePage: (page: GitSidebarPage) => Promise<void>;

  /**
   * Creates a duplicate copy of a page document and appends it to the sidebar
   * adjacent to the page item source.
   */
  duplicatePage: (page: GitSidebarPage) => Promise<void>;

  /**
   * Finds the sidebar category based on the provided `title` with efficient
   * O(1) lookups. Returns `undefined` if no match was found.
   */
  findSidebarCategoryByTitle: (title: string) => GitSidebarCategory | undefined;

  /**
   * Find and return the first page in the sidebar. Excludes `hidden` and `link`
   * pages by default but can be overridden to include them.
   */
  findSidebarFirstPage: (config?: Parameters<typeof findSidebarFirstPageISO>[1]) => GitSidebarPage | null;

  /**
   * Finds the sidebar page based on the provided page `slug` with efficient
   * O(1) lookups. Returns `undefined` if no match was found.
   */
  findSidebarPageBySlug: (slug: string) => GitSidebarPage | undefined;

  /**
   * Updates the sidebar state with data, loading states, error, etc. Call this
   * whenever you need to initialize the sidebar with SSR or incoming API data.
   */
  initialize: (
    payload: Pick<SuperHubSidebarSliceState, 'data' | 'error' | 'isLoading'> & {
      swrKey?: SuperHubSidebarSliceState['swrKey'];
    },
  ) => void;

  /**
   * Updates an existing category with a new title.
   */
  renameCategory: (category: GitSidebarCategory, newTitle: string) => Promise<void>;

  /**
   * Reorders an existing category from its current position to a new position.
   */
  reorderCategory: (title: string, newPosition: number) => Promise<void>;

  /**
   * Reorders an existing page from its current position to a new position.
   */
  reorderPage: (source: ItemDropResult, target: ItemDropResult) => Promise<void>;

  /**
   * Revalidates the sidebar and pulls down the latest data from the API.
   */
  revalidate: () => Promise<GitSidebarCategory[] | undefined>;

  /**
   * Updates our internal cache map based on current state data to enable O(1)
   * lookups. Should be called whenever changes are made to state data.
   */
  updateCache: () => void;
}

export interface SuperHubSidebarSlice {
  /**
   * State slice containing git sidebar data and all actions that can be
   * performed on its categories and page documents.
   */
  sidebar: SuperHubSidebarSliceAction & SuperHubSidebarSliceState;
}

const initialState: SuperHubSidebarSliceState = {
  data: [],
  error: null,
  isLoading: false,
  isReady: false,
  swrKey: null,
  totalUnrenderable: 0,
};

/**
 * Indexed cache to look up categories or pages in O(1) time. Contains a hash
 * map of either the `category.title` or `page.slug` as keys.
 */
const sidebarMap = new SidebarMap();

/**
 * Contains reference to the previously loaded data. We use this to mimic the
 * `keepPreviousData` SWR option that is not yet available for use in our
 * current SWR version. It allows existing sidebar data to continue showing
 * while revalidation occurs in the background.
 * @link https://swr.vercel.app/docs/advanced/understanding#return-previous-data-for-better-ux
 */
let previousData = initialState.data;

/**
 * SuperHub sidebar state slice containing all things related to sidebar data.
 */
export const createSuperHubSidebarSlice: StateCreator<
  SuperHubSidebarSlice & SuperHubStore,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  SuperHubSidebarSlice
> = (set, get) => ({
  sidebar: {
    ...initialState,

    createCategory: async title => {
      if (get().sidebar.findSidebarCategoryByTitle(title)) return;

      set(
        state => {
          state.sidebar.data.push({
            pages: [],
            title,
            uri: `${title}/PENDING`,
          });
        },
        false,
        actionLog('sidebar.createCategory', title),
      );

      const request = fetcher<ReadRepresentationType>(`${get().apiBaseUrl}/categories`, {
        method: 'POST',
        body: JSON.stringify({
          title,
          type: get().routeSection === 'reference' ? 'reference' : 'guide',
        }),
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(({ data }) =>
          produce(get().sidebar.data, draft => {
            draft[draft.length - 1] = {
              pages: [],
              title: data.title,
              uri: data.uri,
            };
          }),
        ),
        { revalidate: false },
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    deleteCategory: async category => {
      set(
        state => {
          const indexToRemove = state.sidebar.data.findIndex(c => c.title === category.title);
          state.sidebar.data.splice(indexToRemove, 1);

          // Update total unrenderable count based on the current sidebar.
          state.sidebar.totalUnrenderable = getTotalUnrenderable(state.sidebar.data);
        },
        false,
        actionLog('sidebar.deleteCategory', category),
      );

      const section = get().routeSection === 'reference' ? 'reference' : 'guides';
      const request = fetcher(`${get().apiBaseUrl}/categories/${section}/${category.title}`, {
        method: 'DELETE',
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(() => get().sidebar.data),
        { revalidate: false },
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    deletePage: async page => {
      const requestUrl = get().getApiEndpoint(page.slug);
      if (!requestUrl) {
        throw new Error('Missing required request URL');
      }

      set(
        state => {
          const parentURI = getPageParentURI(page.parent || page.category);
          const parentEntity = findEntityByURI(state.sidebar.data, parentURI);
          const parentChildren = parentEntity?.pages;

          const indexToRemove = parentChildren?.findIndex(c => c.slug === page.slug);
          if (indexToRemove !== undefined && indexToRemove !== -1) {
            parentChildren?.splice(indexToRemove, 1);
          }

          // Update total unrenderable count based on the current sidebar.
          state.sidebar.totalUnrenderable = getTotalUnrenderable(state.sidebar.data);
        },
        false,
        actionLog('sidebar.deletePage', page),
      );

      const request = fetcher(requestUrl, {
        method: 'DELETE',
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(() => get().sidebar.data),
        { revalidate: false },
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    duplicatePage: async page => {
      const pageUrl = get().getApiEndpoint(page.slug);
      const newPageUrl = get().getApiEndpoint();
      if (!pageUrl || !newPageUrl) {
        throw new Error('Missing required request URL');
      }

      const clone = produce(page, draft => {
        draft.pages = [];
        draft.slug += '/PENDING';
        draft.title += ' (COPY)';
        draft.uri += '/PENDING';
      });
      const noParentError = new Error('Cannot find parent entity of duplicated page');

      let clonePosition = 0;
      set(
        state => {
          const parentURI = getPageParentURI(page.parent || page.category);
          const parentEntity = findEntityByURI(state.sidebar.data, parentURI);
          if (!parentEntity) throw noParentError;

          // Update sidebar to include duplicated page adjacent to the source.
          const parentPages = parentEntity.pages;
          clonePosition = parentPages.findIndex(c => c.slug === page.slug) + 1;
          parentPages.splice(clonePosition, 0, clone);

          // Update total unrenderable count based on the current sidebar.
          state.sidebar.totalUnrenderable = getTotalUnrenderable(state.sidebar.data);
        },
        false,
        actionLog('sidebar.duplicatePage', page),
      );

      // Fetch original page so we can then create a duplicate of it.
      const { data: sourceDocument } = await fetcher<ReadGuideType>(pageUrl, {
        method: 'GET',
      });

      // Save a new page that is a clone of the original page.
      const request = fetcher<ReadGuideType>(newPageUrl, {
        body: JSON.stringify(
          produce<CreateGuideType>(sourceDocument, draft => {
            draft.position = clonePosition;
            draft.slug = undefined;
            draft.title = clone.title;
          }),
        ),
        method: 'POST',
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(({ data: newDocument }) =>
          produce(get().sidebar.data, draft => {
            const pageDraft = findEntityByURI(draft, clone.uri);
            if (!pageDraft || !('slug' in pageDraft)) throw noParentError;
            pageDraft.slug = newDocument.slug;
            pageDraft.title = newDocument.title;
            pageDraft.uri = newDocument.uri;
          }),
        ),
        { revalidate: false },
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    findSidebarCategoryByTitle: title => {
      return sidebarMap.categories[title.toLowerCase()];
    },

    findSidebarFirstPage: ({ includeHidden = false, includeLinks = false } = {}) => {
      return findSidebarFirstPageISO(get().sidebar.data, {
        includeHidden,
        includeLinks,
      });
    },

    findSidebarPageBySlug: slug => {
      return sidebarMap.pages[slug];
    },

    initialize: payload => {
      const { data, error, isLoading, swrKey } = payload;
      /** Indicates we are connected to an SWR endpoint. */
      const isConnected = !!get().sidebar.swrKey;
      /** Indicates we are updating the currently connected SWR endpoint. */
      const isSameEndpoint = !isConnected || swrKey === get().sidebar.swrKey;
      const nextData = {
        // While sidebar data is revalidating, continue returning existing data
        // until new data replaces it.
        data: isLoading && isSameEndpoint ? previousData : data,
        error,
        isLoading,
      };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return JSON.stringify(value) !== JSON.stringify(get().sidebar[key]);
      });
      if (!hasChanges) return;

      set(
        state => {
          state.sidebar = { ...state.sidebar, ...nextData };

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.sidebar.isReady = isClient;

          // Update SWR key that indicates we are connected to an endpoint.
          const writableSwrKey = swrKey as WritableDeep<typeof swrKey>;
          state.sidebar.swrKey = writableSwrKey ?? null;

          // Update total unrenderable count based on the current sidebar.
          state.sidebar.totalUnrenderable = getTotalUnrenderable(state.sidebar.data);
        },
        false,
        actionLog('sidebar.initialize', payload),
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    renameCategory: async (category, newTitle) => {
      set(
        state => {
          const target = state.sidebar.data.find(c => c.title === category.title);
          if (target) {
            target.title = newTitle;
          }
        },
        false,
        actionLog('sidebar.renameCategory', { category, newTitle }),
      );

      const section = get().routeSection === 'reference' ? 'reference' : 'guides';
      const request = fetcher<ReadRepresentationType>(`${get().apiBaseUrl}/categories/${section}/${category.title}`, {
        method: 'PATCH',
        body: JSON.stringify({ title: newTitle }),
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(({ data }) =>
          produce(get().sidebar.data, draft => {
            const target = draft?.find(c => c.title === newTitle);
            if (target) {
              target.title = data.title;
              target.uri = data.uri;
            }
          }),
        ),
        { revalidate: false },
      );

      // Update our index caches to enable 0(1) lookups.
      get().sidebar.updateCache();
    },

    reorderCategory: async (uri, newPosition) => {
      const title = getCategoryFromURI(uri)?.title;
      if (!title) {
        throw new Error(`Category title cannot be parsed from the URI: "${uri}"`);
      }

      const currentPosition = get().sidebar.data.findIndex(c => c.title === title);
      if (currentPosition < 0) {
        throw new Error(`Category cannot be found with the provided title: "${title}"`);
      }

      set(
        state => {
          // Remove category from the array in its current position. Note that
          // this mutates the array in-place and will shift the array length.
          const [sourceCategory] = state.sidebar.data.splice(currentPosition, 1);
          // Re-insert source category into its final position.
          state.sidebar.data.splice(newPosition, 0, sourceCategory);
        },
        false,
        actionLog('sidebar.reorderCategory', { uri, currentPosition, newPosition }),
      );

      const section = get().routeSection === 'reference' ? 'reference' : 'guides';
      const request = fetcher<ReadRepresentationType>(`${get().apiBaseUrl}/categories/${section}/${title}`, {
        method: 'PATCH',
        body: JSON.stringify({ position: newPosition }),
      });

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(() => get().sidebar.data),
        { revalidate: false },
      );
    },

    reorderPage: async (source, target) => {
      const { slug } = findEntityByURI(get().sidebar.data, source.id) as GitSidebarPage;
      const requestUrl = get().getApiEndpoint(slug);
      if (!requestUrl) {
        throw new Error('Missing required request URL');
      }

      const categoryChanged = source.categoryId !== target.categoryId;
      const parentChanged = source.parentId !== target.parentId;

      let targetCategory: ReturnType<typeof findEntityByURI>;
      let parent: ReturnType<typeof findEntityByURI>;
      if (categoryChanged || parentChanged) {
        targetCategory = findEntityByURI(get().sidebar.data, target.categoryId!);
        if (!targetCategory) {
          throw new Error(`Category cannot be found with the provided uri: "${target.categoryId}"`);
        }
        parent = findEntityByURI('pages' in targetCategory ? targetCategory.pages : [], target.parentId || '');
      }

      const request = fetcher<ReadRepresentationType>(requestUrl, {
        method: 'PATCH',
        body: JSON.stringify({
          ...(categoryChanged && { category: { uri: targetCategory?.uri } }),
          // When the parent changes, we need to provide the parent and category
          // so we know where the new home for the page is in the event we're
          // completely removing the parent.
          ...(parentChanged && {
            parent: { uri: parent?.uri || null },
            category: { uri: targetCategory?.uri },
          }),
          position: target.position,
        }),
      });

      set(
        state => {
          const getSiblings = (entityUri: string) => {
            const parentEntity = findEntityByURI(state.sidebar.data, entityUri);
            const parentChildren = parentEntity?.pages;
            return parentChildren;
          };

          // Remove page from the array in its current position. Note that this
          // mutates the array in-place and will shift the array length.
          const sourceSiblings = getSiblings(source.parentId || source.categoryId || '');
          const indexToRemove = sourceSiblings?.findIndex(c => c.uri === source.id);
          if (indexToRemove === undefined || indexToRemove === -1) return;

          if (!sourceSiblings) return;
          const [sourcePage] = sourceSiblings.splice(indexToRemove, 1);

          // Re-insert source page into its final position.
          const targetSiblings = getSiblings(target.parentId || target.categoryId || '');
          if (!targetSiblings) return;
          targetSiblings.splice(target.position, 0, sourcePage);
        },
        false,
        actionLog('sidebar.reorderPage', { source, target }),
      );

      await mutate<GitSidebarCategory[]>(
        get().sidebar.swrKey,
        request.then(() => get().sidebar.data),
        { revalidate: false },
      );
    },

    revalidate: () => {
      return mutate<GitSidebarCategory[]>(get().sidebar.swrKey);
    },

    updateCache: () => {
      // Update our cache for fast lookups.
      sidebarMap.update(get().sidebar.data);

      // Keep reference to our previous data.
      previousData = get().sidebar.data;
    },
  },
});

export * from './ConnectSuperHubSidebarToApi';
export * from './InitializeSuperHubSidebar';
