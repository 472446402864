import { HubCustomStylesMarker } from '@readme/iso';

/**
 * Replace the custom CSS in the Hub2 stylesheet with the provided styles.
 *
 * Looks for the following comment blocks in the "rm-custom-css" stylesheet:
 * /*! BEGIN HUB_CUSTOM_STYLES *\/
 * ...
 * /*! END HUB_CUSTOM_STYLES *\/
 *
 * and replaces the contents with the provided styles.
 *
 * @example
 * // Before
 * <style title="rm-custom-css">
 *   h1 { color: red; }
 *   /*! BEGIN HUB_CUSTOM_STYLES *\/
 *   .something-old { color: blue; }
 *   /*! END HUB_CUSTOM_STYLES *\/
 *   button { color: green; }
 * </style>
 *
 * // After liveUpdateHub2CSS('.something-new { color: purple; }');
 * <style title="rm-custom-css">
 *   h1 { color: red; }
 *   /*! BEGIN HUB_CUSTOM_STYLES *\/
 *   .something-new { color: purple; }
 *   /*! END HUB_CUSTOM_STYLES *\/
 *   button { color: green; }
 * </style>
 */
function liveUpdateHub2CSS(styles: string) {
  const styleElement = document.querySelector<HTMLStyleElement>('style[title="rm-custom-css"]');
  if (!styleElement) return;

  const overrideStyleElement = document.createElement('style');
  overrideStyleElement.title = 'rm-custom-css';

  const text = `${styleElement.textContent || styleElement.innerText}`;

  const beforeBeginMarker = text.split(HubCustomStylesMarker.BEGIN)[0];
  const afterEndMarker = text.split(HubCustomStylesMarker.END)[1];

  const update = beforeBeginMarker + HubCustomStylesMarker.BEGIN + styles + HubCustomStylesMarker.END + afterEndMarker;

  overrideStyleElement.innerText = update;
  styleElement?.replaceWith(overrideStyleElement);
}

export default liveUpdateHub2CSS;
