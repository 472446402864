import loadable from '@loadable/component';

/**
 * Loadable component that dynamically imports an SVG icon based on the "name"
 * prop provided.
 *
 * NOTE: We intentionally define this LoadableComponent in a JSX module instead
 * of TSX because it requires @loadable/babel-plugin to work correctly with our
 * React SSR setup. Our current webpack config loads all TSX files through
 * "ts-loader" instead of "babel-loader". Thus, when defined inside a TSX file,
 * the "@loadable/babel-plugin" is missing and causes an SSR error.
 *
 * For now, the simplest solution is to abstract this LoadableComponent out of
 * TS. We can try augmenting the ts-loader to run in sequence with babel-loader
 * to ensure babel plugins are loaded even for our TS(X) files. But this was
 * already attempted with other errors blocking progress.
 *
 * @param {Object} props
 * @return {LoadableComponent}
 */
const AsyncIcon = loadable(props => import(`@core/icons/${props['data-name']}.svg`));

export default AsyncIcon;
