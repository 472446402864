export enum NavigationPageType {
  API_REFERENCE = 'reference',
  CHANGELOG = 'changelog',
  CUSTOM_PAGE = 'custompage',
  DISCUSSIONS = 'discuss',
  GUIDES = 'docs',
  HOME = 'home',
  LINK_URL = 'url',
  RECIPES = 'recipes',
  SEARCH_BOX = 'search',
  USER_CONTROLS = 'user',
}
