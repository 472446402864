import { loadableReady } from '@loadable/component';
import PoweredBy from '@readme/server-shared/powered-by-log'; // eslint-disable-line readme-internal/no-restricted-imports
import React from 'react';
import { hydrate } from 'react-dom';

import DebugRouter from '@core/utils/DebugRouter';

import App from '.';

PoweredBy();

// eslint-disable-next-line no-multi-assign
let FIRST_RUN = (window.FIRST_RUN = !('rehydrate' in window));

if (FIRST_RUN) {
  window.rehydrate = (props = {}) => {
    const root = document.getElementById('ssr-main');
    if (root) {
      hydrate(
        <DebugRouter basename={props.baseUrl} forceRefresh={!!props.is404}>
          <App {...props} />
        </DebugRouter>,
        root,
      );
    }
  };
}
const rehydrate = window.rehydrate;

function initialize() {
  if (FIRST_RUN) {
    // run an immediate render pass
    let initialProps;
    try {
      const elem = document.querySelector('#ssr-props[data-initial-props]');
      const data = elem.dataset.initialProps;
      initialProps = JSON.parse(data);
      elem.remove();
    } catch (e) {
      initialProps = {};
    }
    loadableReady(() => rehydrate(initialProps));

    // set up globals
    window.rehydrate = () => rehydrate(initialProps);

    // toggle the init flag
    FIRST_RUN = false;
  }
}

const holdInitialization = setInterval(() => {
  if (typeof $ !== 'undefined') {
    clearInterval(holdInitialization);
    initialize();
  }
}, 300);
