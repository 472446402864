let ranOnce = false;

export default function PoweredByLog(text = 'Check out https://readme.com.', header = true, force = false) {
  if (!force && ranOnce) return;

  const svgString =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.1 43.74"><path d="M676.26 131.43h-14.55a10.72 10.72 0 0 0-10.46 8.36 10.72 10.72 0 0 0-10.46-8.36h-14.54a5 5 0 0 0-5 5v25.6a5 5 0 0 0 5 5h9.34c10.64 0 13.82 2.56 15.34 7.83a.28.28 0 0 0 .28.21h.06a.28.28 0 0 0 .28-.21c1.53-5.27 4.71-7.83 15.34-7.83h9.34a5 5 0 0 0 5-5v-25.6a5 5 0 0 0-4.97-5Zm-29.81 27.63a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36v-2.38a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Zm0-6.34a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36v-2.38a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Zm0-6.34a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36V144a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Zm27 12.67a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36v-2.38a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Zm0-6.34a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36v-2.38a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Zm0-6.34a.36.36 0 0 1-.36.36h-16.7a.36.36 0 0 1-.36-.36V144a.36.36 0 0 1 .36-.36h16.7a.36.36 0 0 1 .36.36v2.38Z" style="fill:#018ef5" transform="translate(-621.2 -131.43)"/></svg>';
  const svgUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;

  const use = {
    line: !!text,
    logo: [
      /Safari/.test(navigator.userAgent) && /Apple Comp/.test(navigator.vendor),
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor),
    ],
  };
  const css = {
    head: `
      color: dodgerblue;
      font-size: 18px;
    `,
    logo: `
      font-size: 1px;
      margin-right: 5px;
      padding: 15px 0 4px 27px;
      background:
        url(${svgUrl})
        0 50% / 27px 20px
        no-repeat;
    `,
  };
  const log = {
    S: '%cPowered by %cReadMe',
    C: [css.head, `${css.head}; font-weight: 900;`],
    logo: {
      S: '%c %c',
      C: [css.logo, ''],
    },
    line: {
      S: `%c\n${text}\n `,
      C: ['color: dodgerblue; font-style: italic'],
    },
  };

  if (!header) {
    log.S = '';
    log.C = [];
  } else if (use.logo.indexOf(true) >= 0) {
    log.S = `${log.logo.S}${log.S}`;
    log.C.unshift(...log.logo.C);
  }

  if (use.line) {
    log.S += log.line.S;
    log.C.push(...log.line.C);
  }

  console.log(`\n${log.S}`, ...log.C);

  ranOnce = true;
}
