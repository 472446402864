import type { MetricsUserVariable } from './types';
import type { $TSFixMe } from '@readme/iso';
import type { Operation } from 'oas/operation';

type Key = Record<string, $TSFixMe>;

type User = MetricsUserVariable['user'];

/**
 * Builds a flattened array of security requirements for an operation.
 */
export const buildFlattenedSecurityRequirements = (operation?: Operation): string[] => {
  if (!operation) {
    return [];
  }

  const securityRequirements = operation.getSecurity();
  return securityRequirements.reduce<string[]>((acc, curr) => {
    return [...acc, ...Object.keys(curr)];
  }, []);
};

export const getIdFromKey = (key: Key, securitySchemes: string[] = []) => {
  const groupKey = ['id', 'apiKey', ...securitySchemes, 'name'].find(k => !!key[k]);
  if (!groupKey) {
    return undefined;
  }

  return key[groupKey];
};

/**
 * Gets the group ID from the keys array for the current operation.
 */
const getFromKeys = (keys: Key[] = [], securitySchemes: string[] = []): string | undefined => {
  if (!keys || !keys.length) {
    return undefined;
  }

  // Look for thr first key containing a security scheme
  const relevantKey =
    keys.find(key => {
      return [...securitySchemes].some(keyName => key[keyName]);
    }) ?? keys[0];

  return getIdFromKey(relevantKey, securitySchemes);
};

/**
 * Gets the group ID from the user object for the current operation.
 */
const getFromUser = (user: User, securitySchemes: string[]): string | undefined => {
  const groupKey = ['id', 'apiKey', ...securitySchemes, 'email'].find(k => !!user[k]);

  if (!groupKey) {
    return undefined;
  }

  return user[groupKey] as string;
};

/**
 * Gets the group ID from the user object for the current operation.
 * It will first look in the keys array, then in the top level user object.
 *
 * When searching the keys array it will look for a key matching the following priority:
 * 1. The key contains the security scheme name for the operation
 * 2. The first key in the array
 *
 * If a key is found it will return the group ID matching the following priority:
 * 1. The key id
 * 2. The key apiKey
 * 3. The key security scheme
 * 4. The key name
 *
 * If no key is found it will return the group ID from the user object matching the following priority:
 * 1. The user id
 * 2. The user apiKey
 * 3. The security scheme value
 * 4. The user email
 *
 * Any security scheme values will be masked.
 *
 * @param user The current user
 */
export const getGroupId = (user: User, operation?: Operation): string | undefined => {
  if (!user) {
    return undefined;
  }

  const flattenedSecurityRequirements = buildFlattenedSecurityRequirements(operation);

  const groupIdFromKeysArray = 'keys' in user ? getFromKeys(user.keys, flattenedSecurityRequirements) : undefined;

  if (groupIdFromKeysArray) {
    // groupId found in keys
    return groupIdFromKeysArray;
  }

  const groupIdFromUser = getFromUser(user, flattenedSecurityRequirements);
  if (groupIdFromUser) {
    // groupId found in user
    return groupIdFromUser;
  }

  return undefined;
};
