import { useContext, useMemo } from 'react';

import { ProjectContext, UserContext } from '@core/context';

const useUserPermissions = () => {
  const user = useContext(UserContext) || {};
  const { permissions = [], _id: userId, is_god: isGod, loggedIn: isLoggedIn, isEndUser } = user;
  const { project } = useContext(ProjectContext) || {};

  const parentId = project.parent?._id?.toString();
  const childId = project._id?.toString();

  // If a parent exists, also use its permissions; otherwise, just use the active project's permissions
  const relevantPerms = useMemo(
    () =>
      permissions.filter(perm =>
        parentId
          ? perm.project?.toString() === childId || perm.project?.toString() === parentId
          : perm.project?.toString() === childId,
      ),
    [permissions, parentId, childId],
  );

  // If user is an admin on this project or its parent then we consider them an admin
  const isAdminUser = useMemo(
    () => isGod || relevantPerms.some(perm => perm.userType === 'admin'),
    [isGod, relevantPerms],
  );

  // If user is an owner or admin of the group that this project belongs to, then we consider them a group admin
  const isGroupAdminUser = useMemo(() => {
    if (!project.parent) return false;

    // If project owner, they should be able to setup what they'd like at the group level
    if (isGod || project.parent.owner?.id === userId) return true;

    // Else check if current user has group-level admin permissions
    return !!permissions.find(p => p?.project?.toString() === project.parent._id?.toString() && p.userType === 'admin');
  }, [isGod, project, permissions, userId]);

  const isOwner = useMemo(() => project.owner?.id === userId, [project, userId]);

  // If user has any permissions, they are considered a member
  // This includes users who are viewers as well as admins or owners
  const isMember = !!relevantPerms.length;

  return { isLoggedIn, isAdminUser, isGroupAdminUser, isOwner, isMember, isEndUser };
};

export default useUserPermissions;
