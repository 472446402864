import type { LegacyRDMDOpts, RMDXOpts } from '@readme/iso/src/types/rdmd';

import React, { useRef } from 'react';
import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { actionLog, createBoundedUseStore } from '../util';

interface RdmdStateBase {
  dehydrated?: {
    body: string;
    toc: string;
  };
}

interface RdmdStateMdx extends RdmdStateBase {
  mdx: true;
  opts: RMDXOpts;
}

interface RdmdStateLegacy extends RdmdStateBase {
  mdx?: false;
  opts: LegacyRDMDOpts;
}

type RdmdState = RdmdStateLegacy | RdmdStateMdx;
type CustomBlocks = Record<string, string>;

export interface RdmdActions {
  reset: (resetData?: Partial<RdmdState>) => void;
  setCustomBlocks: (customBlocks: CustomBlocks) => void;
}

type RdmdStore = RdmdActions & RdmdState;

const initialState: RdmdState = {
  mdx: false,
  opts: {},
};

export const rdmdStore = createStore<RdmdStore>()(
  devtools(
    immer(set => ({
      ...initialState,
      setCustomBlocks: (customBlocks: CustomBlocks) => {
        set(
          state => {
            if (state.mdx) {
              state.opts.components = customBlocks;
            } else {
              state.opts.reusableContent = { ...state.opts.reusableContent, tags: customBlocks };
            }
          },
          false,
          actionLog('setCustomBlocks', customBlocks),
        );
      },
      reset: (resetData?: Partial<RdmdState>) => {
        const nextState = { ...initialState, ...resetData };

        set(
          state => {
            if ('dehydrated' in nextState) {
              state.dehydrated = nextState.dehydrated;
            } else {
              delete state.dehydrated;
            }

            state.opts = nextState.opts;
            state.mdx = nextState.mdx;
          },
          true,
          actionLog('reset', resetData),
        );
      },
    })),
    { name: 'RdmdStore' },
  ),
);

export const useRdmdStore = createBoundedUseStore(rdmdStore);

/**
 * @visibleName Rdmd
 */
export const RdmdStoreInitializer = ({ children, ...props }: React.PropsWithChildren<Partial<RdmdState>>) => {
  const isInitialized = useRef<boolean>(false);
  const reset = useRdmdStore(state => state.reset);

  if (!isInitialized.current) {
    isInitialized.current = true;
    reset(props);
  }

  return <>{children}</>;
};

export default useRdmdStore;
