import type { ProjectDocument } from '@readme/backend/models/project/types';
import type { VersionDocument } from '@readme/backend/models/version';
import type { Request } from 'express';

import * as URL from 'url';

import isLang from '../isLang';
import isVersion from '../isVersion';

export interface Options {
  child?: boolean;
  hasOneChild?: boolean;
  lang?: string;
  parent?: ProjectDocument | null;
  project?: ProjectDocument | null;
  version?: VersionDocument | null;
}

const defaultOptions = {
  child: false,
  parent: null,
  hasOneChild: false,
  project: null,
  version: null,
  lang: 'en',
};

interface Parsed {
  api?: boolean;
  child?: string;
  hash?: string;
  host?: string;
  hostname?: string;
  is_version_stable?: boolean;
  lang?: string;
  protocol?: string;
  query?: string;
  slugs?: string[];
  version?: string;
}

class UrlManager {
  options: Options;

  defaults: Parsed;

  constructor(providedOpts: Options) {
    const options = { ...defaultOptions, ...providedOpts };
    this.options = options;

    this.defaults = {
      child:
        options.child || (options.parent && !options.hasOneChild)
          ? UrlManager.getChildName(options.project)
          : undefined,
      lang: options.lang,
      version: options.version?.version,
    };
  }

  static optionsFrom(req: Request) {
    const parent = req.project && req.project._parent && { ...req.project._parent };
    if (parent) {
      parent.childrenProjects = req.project._parent.childrenProjects.map(UrlManager.getChildName);
    }

    const hasOneChild =
      parent && parent.childrenProjects.length === 1 && req.project._parent.flags.singleProjectEnterprise;

    return {
      ...defaultOptions,
      parent,
      hasOneChild,
      project: req.project,
      lang: req.params?.lang || 'en',
      version: req.version,
    };
  }

  static getChildName(child: ProjectDocument | null): string {
    return child?.subpath ? child.subpath : child?.subdomain ?? '';
  }

  static fromReq(req: Request): UrlManager {
    return new UrlManager(UrlManager.optionsFrom(req));
  }

  getChild(firstPathPiece: string): string | null {
    if (this.options.child) return this.defaults.child || null;
    if (!this.options.parent) return null;
    return this.options.parent.childrenProjects?.find(x => (x as ProjectDocument['_id']) === firstPathPiece) || null;
  }

  parse(originalPath: string, useDefaults = false) {
    let path = originalPath;
    if (typeof window !== 'undefined' && !path) path = window.location.pathname;
    // eslint-disable-next-line node/no-deprecated-api
    const parsedUrl = URL.parse(path);
    const split = parsedUrl.pathname?.split('/').filter(p => p) || [];

    const child = this.getChild(split[0]);

    let parsed = parsedUrl as Parsed;
    if (child) {
      parsed.child = child;
      if (parsed.child && parsed.child === split[0]) split.shift();
      if (this.options.hasOneChild) parsed.child = undefined;
    }

    if (split[0] === 'api') {
      parsed.api = true;
      split.shift();
    }

    if (isLang.test(split[0])) {
      const match = split[0].match(isLang) as RegExpMatchArray;
      parsed.lang = match[0].replace('lang-', '');
      split.shift();
    }

    const versionReg = /(?!v)([0-9]+)(?:\.([0-9]+))?(?:\.([0-9]+))?(-.*)?/;
    if (split[0] && isVersion(split[0])) {
      const match = split[0].match(versionReg) as RegExpMatchArray;
      parsed.version = match[0];
      split.shift();
    }

    parsed.slugs = split;

    if (useDefaults) {
      parsed = { ...this.defaults, ...parsed };
    }

    if (parsed.version) {
      parsed.is_version_stable = parsed.version === this.defaults.version && this.options.version?.is_stable;
    }

    return parsed;
  }

  static stringify(parsed: Parsed, absolute = false): string {
    let total = '';
    if (parsed.child) {
      total += `/${parsed.child}`;
    }

    if (parsed.api) {
      total += '/api';
    }

    if (parsed.lang && parsed.lang !== 'en') {
      total += `/lang-${parsed.lang}`;
    }

    if (parsed.version && !parsed.is_version_stable) {
      total += `/v${parsed.version}`;
    }

    if (parsed.slugs?.length) {
      const slugs = parsed.slugs.join('/');
      total += `/${slugs}`;
    }

    if (parsed.query) {
      total += `?${parsed.query}`;
    }

    if (parsed.hash) {
      total += `${parsed.hash}`;
    }

    if (total === '') {
      total = '/';
    }

    if (absolute) {
      return `${parsed.protocol}//${parsed.hostname}${total}`;
    }

    return total;
  }

  upsert(type: string, value: unknown, path: string): string {
    const parsed = this.parse(path);
    parsed[type] = value;
    return UrlManager.stringify(parsed);
  }

  remove(type: string, path: string, absolute = false): string {
    const parsed = this.parse(path);
    delete parsed[type];
    return UrlManager.stringify(parsed, absolute);
  }

  generate(path: string, options: Options): string {
    const parsed = (Object.entries(options) as [keyof Options, Options[keyof Options]][]).reduce(
      (acc: Parsed, [property, shouldInclude]) => {
        if (!shouldInclude) delete acc[property];
        return acc;
      },
      this.parse(path, true),
    );
    return UrlManager.stringify(parsed);
  }

  baseUrl(url: string, trailingSlash = true): string {
    const parsed = this.parse(url);
    parsed.slugs = [];
    delete parsed.hash;
    delete parsed.query;
    return trailingSlash
      ? UrlManager.stringify(parsed, !!parsed.host)
      : UrlManager.stringify(parsed, !!parsed.host).replace(/\/$/, '');
  }

  relative(path: string): string {
    const parsed = this.parse(path, true);
    return UrlManager.stringify(parsed);
  }

  absolute(path: string): string {
    const parsed = this.parse(path, true);
    return UrlManager.stringify(parsed, true);
  }
}

export default UrlManager;
