import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import qs from 'qs';
import { createStore } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { stringifyOptions } from '@core/store/Metrics/constants';
import type { MetricsFilters } from '@core/types/metrics';
import { omit } from '@core/utils/lodash-micro';

import { actionLog, createBoundedUseStore, isClient } from '../util';

interface RealtimeStoreState {
  /**
   * Object representation of current filters used to fetch data from the API
   * Gets stringified at request level via qs package
   */
  filters: MetricsFilters;

  /**
   * Internal flag to determine if the store has been hydrated from local storage
   */
  hasHydrated: boolean;

  /**
   * Indicates that the store has been initialized to its beginning state via
   * `initialize()` action and lets connected subscribers know that it is ready
   * for consumption.
   * @see initialize
   */
  isReady: boolean;
}

interface RealtimeStoreAction {
  /**
   * Selector function to return the qs.stringify() result of state.filters
   * @param omitKeys - optional array of keys to omit from the filters object
   * @param pickKeys - optional array of keys to pick from the filters object
   */
  getFiltersQueryString: (options?: { omitKeys?: string[]; pickKeys?: string[] }) => string;

  /**
   * Returns true if the current filters have changed from the initial filters
   * that were set when the store was first initialized.
   */
  getHasChangedFromInitialFilters: () => boolean;

  /**
   * Initializes the store based w/ base filters data
   * This action should only be called once when the store is first created.
   */
  initialize: (filters: RealtimeStoreState['filters']) => void;

  /**
   * Removes a filter from the `filters` state property.
   */
  removeFilter: (filterKey: string, filterValue: number | string) => void;

  /**
   * Resets state back to the last "initialized" state. Useful when you need to
   * update the store to some temporary state (e.g. tests or examples) and
   * always revert the store back to its original state.
   */
  reset: () => void;

  /**
   * Resets the `filters` state property back to its initial state
   */
  resetFilters: () => void;

  /**
   * Set the hasHydrated state
   */
  setHasHydrated: (state: boolean) => void;

  /**
   * Update the `filters` state property.
   */
  updateFilters: (nextFilters: Partial<MetricsFilters>) => void;
}

export type RealtimeStore = RealtimeStoreAction & RealtimeStoreState;

const initialState: RealtimeStoreState = {
  filters: {
    page: 0,
    pageSize: 30,
    sort: 'createdAt',
    direction: 'desc',
    path: [],
    method: [],
    status: [],
    useragent: [],
    rangeLength: 24,
    resolution: 'hour',
  },
  isReady: false,
  hasHydrated: false,
};

/**
 * Realtime store that contains filters and data used by Realtime pages (/my-requests).
 * This store can be accessed and used on /my-requests page.
 * React components should call `useRealtimeStore()` instead.
 * @example
 * import { realtimeStore } from '@core/store';
 *
 * const isReady = realtimeStore.getState().isReady;
 */
export const realtimeStore = createStore<RealtimeStore>()(
  devtools(
    immer(
      persist(
        (set, get) => {
          /**
           * Holds reference to the initial state so we can support resetting the
           * store back to this state when calling `reset()`.
           */
          const resetState = initialState;

          return {
            ...initialState,

            // Hydrate the store with the state from local storage
            setHasHydrated: state => {
              set({
                hasHydrated: state,
              });
            },

            initialize: filters => {
              // Initialize our beginning state only once before it is "ready".
              if (get().isReady) return;

              set(
                state => {
                  state.filters = { ...state.filters, ...filters };
                  // Mark store as "ready" only when running on the client. This
                  // ensures the store continues receiving updates until it gets
                  // initialized on the client's first render.
                  state.isReady = !!isClient;
                },
                false,
                actionLog('initialize', filters),
              );
            },

            getFiltersQueryString: ({
              omitKeys = [],
              pickKeys = [],
            }: { omitKeys?: string[]; pickKeys?: string[] } = {}) => {
              const filters = get().filters;
              let filteredFilters;

              if (omitKeys.length > 0) {
                filteredFilters = omit(filters, omitKeys);
              } else if (pickKeys.length > 0) {
                filteredFilters = pick(filters, pickKeys);
              } else {
                filteredFilters = filters;
              }

              return qs.stringify(filteredFilters, stringifyOptions);
            },

            getHasChangedFromInitialFilters: () => {
              const keysToOmit = ['groupId', 'page', 'rangeLength', 'resolution'];
              const filters = get().filters;

              return !isEqual(omit(filters, keysToOmit), omit(initialState.filters, keysToOmit));
            },

            reset: () => {
              set(resetState, false, actionLog('reset'));
            },

            updateFilters: nextFilters => {
              set(
                state => {
                  // If group ID changes, reset all filters back to initial state
                  // But still maintain active range and resolution, as these are set via initialize()
                  if (nextFilters.groupId && state.filters.groupId !== nextFilters.groupId) {
                    state.filters = {
                      ...initialState.filters,
                      rangeLength: state.filters.rangeLength,
                      resolution: state.filters.resolution,
                      ...nextFilters,
                    };
                  } else {
                    state.filters = { ...state.filters, ...nextFilters };
                  }
                },
                false,
                actionLog('updateFilters', nextFilters),
              );
            },

            removeFilter: (filterKey, filterValue) => {
              set(
                state => {
                  const currentFilterValues = state.filters[filterKey];

                  if (Array.isArray(currentFilterValues)) {
                    const index = currentFilterValues.findIndex(
                      val => val.toLowerCase() === String(filterValue).toLowerCase(),
                    );

                    if (index !== -1) {
                      currentFilterValues.splice(index, 1);
                    }
                  }

                  state.filters.page = 0;
                },
                false,
                actionLog('removeFilter', filterValue),
              );
            },

            resetFilters: () => {
              set(
                state => {
                  state.filters = {
                    ...initialState.filters,
                    rangeLength: state.filters.rangeLength,
                    resolution: state.filters.resolution,
                    groupId: state.filters.groupId,
                  };
                },
                false,
                actionLog('resetFilters'),
              );
            },
          };
        },
        {
          // Persist the store to localStorage for rehydration on page reloads
          name: 'RealtimeStore',
          onRehydrateStorage: () => state => {
            state?.setHasHydrated(true);
          },
          partialize: (state): Partial<RealtimeStoreState> => {
            // On rehydration, only persist date range values
            const { groupId, rangeEnd, rangeLength, rangeStart, resolution } = state.filters;
            return {
              isReady: state.isReady,
              filters: { rangeStart, rangeEnd, rangeLength, resolution, groupId },
            };
          },
        },
      ),
    ),
    { name: 'RealtimeStore' },
  ),
);

/**
 * Bound react hook to access our Realtime store. Must be called within a React
 * component. To access the store outside of React, use `realtimeStore` instead.
 * @example
 * import { useRealtimeStore } from '@core/store';
 *
 * function Component() {
 *   const filters = useRealtimeStore(s => s.filters);
 * }
 */
export const useRealtimeStore = createBoundedUseStore(realtimeStore);
