import type { FetcherOptions, FetcherResponse } from '.';

import Cookie from 'js-cookie';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

interface MetricsHeaders extends Headers {
  entries: () => Entries<{ name: string; value: string }>;
}

interface MetricsError extends Error {
  info?: Response | unknown;
  status: number;
}

async function fetcher<T>({
  apiKey,
  body,
  headers: additionalHeaders,
  host,
  method = 'GET',
  path,
}: FetcherOptions): Promise<FetcherResponse<T>> {
  const headers = new Headers({
    'cache-control': 'no-cache',
    'content-type': 'application/json',
    'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
  });

  // Add authorization header only if apiKey is present
  if (apiKey) {
    headers.append('authorization', `Basic ${Buffer.from(`${apiKey}:`).toString('base64')}`);
  }

  // Append any additional headers to the base headers
  if (additionalHeaders) {
    Object.entries(additionalHeaders).forEach(([key, value]) => {
      headers.append(key, value);
    });
  }

  const res = await fetch(`${host}/${path}`, {
    headers,
    mode: 'cors',
    method,
    body: body ? JSON.stringify(body) : undefined,
  });

  const response = await res.json();

  /**
   * Handle error responses.
   */
  if (!res.ok) {
    const error = new Error("Couldn't fetch metrics data.") as MetricsError;
    error.info = response;
    error.info = error?.info || {};
    error.status = res.status;
    throw error;
  }

  return {
    response,
    headers: Object.fromEntries((res.headers as MetricsHeaders).entries()),
  };
}

export default fetcher;
