import type { SWRResponse } from 'swr';

import { useEffect } from 'react';

/**
 * If the user is unauthorized, redirect them to the login page
 */
function useUnauthorizedRedirect(error: SWRResponse['error'] | undefined) {
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (error?.status === 401) {
      // Deferring this call with a timer to avoid any race conditions with components
      // trying to remove a `beforeunload` listener before the redirect occurs
      timer = setTimeout(() => {
        window.location.assign(`/login?redirect=${window.location.pathname}`);
      }, 100);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [error]);
}

export default useUnauthorizedRedirect;
