import type { Toast, ToastOptions } from 'react-hot-toast';

import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';

import useClassy from '@core/hooks/useClassy';
import classy from '@core/utils/classy';

import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

// Default duration for toast notifications
const DEFAULT_DURATION = 8000;

/**
 * In order for notify's toast() to work, <NotificationToaster /> must exist in the DOM
 * Both packages/react/src/Hub and packages/react/src/Dash base index files should already have this component
 * */
export const notify = (
  notificationToBeToasted: Toast['message'],
  /** Note: some ToastOptions will not be applied because we are using toast.custom() */
  options: ToastOptions = {
    position: 'bottom-right',
    duration: DEFAULT_DURATION,
  },
) => {
  toast.custom(
    t => (
      // We need to tap into the toast state to properly have enter/exit animations
      <div className={classy(t.visible ? `${classes.Notification_open}` : `${classes.Notification_close}`)}>
        {notificationToBeToasted}
      </div>
    ),
    { ...options },
  );
};

export interface NotificationProps {
  /** Optional ariaProps - defaults to role='status' with aria-live='polite' */
  ariaProps?: {
    'aria-live': 'assertive' | 'off' | 'polite';
    role: 'alert' | 'status';
  };
  /** Content rendered in the notification; doesn’t work with title or description */
  children?: React.ReactNode;
  className?: string;
  /** Optional description (will only be used if children prop is not passed) */
  description?: string;
  /** Whether or not notification can be dismissed via close icon */
  dismissible?: boolean;
  /** Optional emoji to show to the left of notification content */
  emoji?: string;
  /** Optional onClick callback */
  handleClick?: () => void;
  /** Optional override for icon */
  iconName?: string; // todo: this should map to valid <Icon> enum/types
  /** Type of notification (determines default icon and color) */
  kind?: 'destructive' | 'error' | 'success';
  theme?: 'dark' | 'light';
  /** Optional title (will only be used if children prop is not passed) */
  title?: string;
}

const Notification = ({
  ariaProps = {
    role: 'status',
    'aria-live': 'polite',
  },
  children,
  className,
  description,
  dismissible = false,
  handleClick,
  iconName,
  kind,
  theme = 'light',
  title,
}: NotificationProps) => {
  const bem = useClassy(classes, 'Notification');
  const [isOpen, setIsOpen] = useState(true);

  const handleOnClick = useCallback(() => {
    setIsOpen(!isOpen);

    handleClick?.();
  }, [handleClick, isOpen]);

  const icon = useMemo(() => {
    // If iconName is provided, use that
    if (iconName) return iconName;

    switch (kind) {
      case 'destructive':
      case 'error':
        return 'alert-circle';
      case 'success':
        return 'check';
      default:
        return null;
    }
  }, [iconName, kind]);

  const content = useMemo(() => {
    if (children) return children;

    return (
      <Flex gap="2px" layout="col">
        {!!title && <div className={bem('-title')}>{title}</div>}
        {!!description && <div className={bem('-description')}>{description}</div>}
      </Flex>
    );
  }, [bem, children, description, title]);

  return (
    <Box
      className={bem(
        '&',
        'rm-Notification',
        `_${isOpen ? 'open' : 'close'}`,
        theme && `_${theme}`,
        kind && `_${kind}`,
        className,
      )}
      kind="pop"
      {...ariaProps}
    >
      {!!icon && (
        <div className={bem('-icon')}>
          <Icon name={icon} size="sm" />
        </div>
      )}

      {content}

      {!!dismissible && (
        <Button
          aria-label="Dismiss notification"
          className={bem('-dismissBtn')}
          ghost
          kind="minimum"
          onClick={handleOnClick}
          size="sm"
          text
        >
          <Icon name="x" />
        </Button>
      )}
    </Box>
  );
};

export { default as ErrorNotification } from './ErrorNotification';
export { default as NotificationToaster } from './Toaster';
export default Notification;
