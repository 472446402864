const env = process.env.NODE_ENV || 'development';

export default function useEnvInfo() {
  /* `playground` is the env variable we use to build
   * the readme.preview.io API Explorer site.
   */
  const isPlayground = env === 'playground';
  const isDev = env === 'development';
  const isPr = env === 'pr';
  const isNext = env === 'next';
  const isStage = env === 'stage';
  const isProd = env === 'production';
  const isTest = env === 'test';

  const isServer = typeof window === 'undefined';
  const isClient = typeof window !== 'undefined';

  return {
    env,
    isServer,
    isClient,
    isDev,
    isPr,
    isNext,
    isStage,
    isProd,
    isPlayground,
    isTest,
  };
}
