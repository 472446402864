// Inspired by https://usehooks.com/useEventListener/
import { useRef, useEffect } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

// mostly copied from here: https://stackoverflow.com/a/72369259
type Events = DocumentEventMap &
  HTMLElementEventMap &
  WindowEventMap & { 'editor:replace': CustomEvent; 'editor:toggleRaw': CustomEvent };
type ListenerElements = Document | HTMLElement | Window;

type UseEventListenerOptions = AddEventListenerOptions & {
  target?: ListenerElements | null;
};

function useEventListener<E extends Events[keyof Events]>(
  eventName: keyof Pick<Events, { [K in keyof Events]: Events[K] extends E ? K : never }[keyof Events]>,
  handler: (event: E) => void,
  { target, ...options }: UseEventListenerOptions = {},
) {
  const { isServer } = useEnvInfo();
  // Fallback to window, but if SSR then set it to undefined
  const element = target || (isServer ? undefined : window);

  // Create a ref that stores handler
  const savedHandler = useRef<((event: E) => void) | null>(null);
  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    if (!element) return () => {};
    // Make sure element supports addEventListener
    // On
    const isSupported = Boolean(element && element.addEventListener);
    if (!isSupported) return () => {};
    // Create event listener that calls handler function stored in ref
    const eventListener = (event: E) => {
      if (!savedHandler?.current) return;
      savedHandler.current(event);
    };

    // Add event listener
    element.addEventListener(eventName, eventListener as EventListener, options);
    // Remove event listener on cleanup
    return () => {
      element.removeEventListener(eventName, eventListener as EventListener);
    };
    // @note When using an options object, the most common case is that the
    // options will never change, but the object will be recreated each render.
    // So lets skip adding to the dep array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventName, element]);
}

export default useEventListener;
