import type { ReadAPIDefinitionCollectionType } from '@readme/api/src/mappings/apis/types';

import React, { useEffect } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';
import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSuperHubStore } from '..';

interface ConnectSuperHubApiDefinitionsProps {
  children: React.ReactNode;

  /**
   * Disables the connection between store and API endpoint.
   */
  disabled?: boolean;

  /**
   * Page document that is typically sourced from route data or fetched directly
   * from the v2 API endpoint.
   */
  initialDefinitions?: ReadAPIDefinitionCollectionType['data'];
}

/**
 * Initializes the SuperHub store's API definition slice with initial data and
 * connects to the API definitions endpoint to pull down the latest data. Use
 * this to initialize the store and enable SSR for connected components.
 * @example
 * <ConnectSuperHubAPIDefinition initialDefinitions={ssrProps.apiDefinitions}>
 *   ...
 * </ConnectSuperHubAPIDefinition>
 */
export function ConnectSuperHubApiDefinitions({
  children,
  disabled = false,
  initialDefinitions,
}: ConnectSuperHubApiDefinitionsProps) {
  const { isServer } = useEnvInfo();
  const [initialize, isReady, apiBaseUrl] = useSuperHubStore(s => [
    s.apiDefinitions.initialize,
    s.apiDefinitions.isReady,
    s.apiBaseUrl,
  ]);

  // To enable SSR, we must call this inline rather than inside an effect. On
  // the server, ignore the "ready" state b/c our global stores on the server
  // persist indefinitely and won't ever reset the "ready" state to false.
  if (isServer || !isReady) {
    initialize({
      data: initialDefinitions ?? null,
      isLoading: false,
    });
  }

  // Everything below this point contains logic to "connect" our store to the
  // API definitions endpoint.
  const endpoint = disabled ? null : `${apiBaseUrl}/apis`;
  const {
    data: collection,
    isLoading,
    swrKey,
  } = useReadmeApi<ReadAPIDefinitionCollectionType>(endpoint, {
    swr: {
      revalidateOnFocus: true,
      shouldRetryOnError: true,
    },
  });

  useEffect(() => {
    initialize({
      data: collection?.data || null,
      isLoading,
      swrKey,
    });
  }, [collection?.data, initialize, isLoading, swrKey]);

  return <>{children}</>;
}
