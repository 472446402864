import type { SuperHubStore } from '..';
import type { ErrorType } from '@readme/api/src/core/legacy_mappings/error';
import type { CreateChangelogType, ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type {
  ReadCustomBlockGitCollectionType,
  ReadCustomBlockGitType,
} from '@readme/api/src/mappings/customblock/types';
import type { CreateCustomPageType, CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { CreateGuideType, ReadGuideType } from '@readme/api/src/mappings/page/guide/types';
import type { CreateReferenceType, ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';
import type { WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import { createCustomBlocksHash } from '@readme/iso';
import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { rdmdStore } from '@core/store';
import { actionLog, isClient } from '@core/store/util';
import type { HTTPError } from '@core/utils/types/errors';

import { isChangelog, isCustomPage, isGuidesPage, isReferencePage } from './util';

export type SuperHubDocumentData =
  | CustomPageReadType['data']
  | ReadChangelogType['data']
  | ReadGuideType['data']
  | ReadReferenceType['data'];
export type SuperHubGuideReferencePage = ReadGuideType['data'] | ReadReferenceType['data'];

/**
 * Request payload type for "create" endpoints.
 */
export type SuperHubCreateDocumentData =
  | CreateChangelogType
  | CreateCustomPageType
  | CreateGuideType
  | CreateReferenceType;

/**
 * Response document type from "create" API endpoints.
 */
type CreateDocumentResponse<CreateDocument> = CreateDocument extends CreateChangelogType
  ? ReadChangelogType
  : CreateDocument extends CreateCustomPageType
    ? CustomPageReadType
    : CreateDocument extends CreateGuideType
      ? ReadGuideType
      : ReadReferenceType;

interface SuperHubDocumentSliceState {
  /**
   * Collection of custom blocks that are used in the current document. This
   * data is used to hydrate the MarkdownEditor with a document's initial custom
   * block data as well as to provide RDMD with the necessary custom block values
   * when parsing the document.
   */
  customBlocks: ReadCustomBlockGitCollectionType['data'];

  /**
   * Dictionary of the current `customBlocks` collection. This data is used to
   * update the RDMD store to ensure proper parsing of the current document's
   * markdown. Whenever `customBlocks` is mutated, this hash representation is
   * and must also be updated.
   */
  customBlocksHash: Record<ReadCustomBlockGitType['data']['tag'], ReadCustomBlockGitType['data']['source']>;

  /**
   * Primary data source for the current page.
   */
  data: SuperHubDocumentData | null;

  /**
   * Whether document data is currently being fetched via API.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized on both server and client. We use
   * this to differentiate how the state should update during SSR vs CSR. On the
   * server, the state should never prevent updates or retain previously
   * existing data. This is only safe to do once this flag is flipped to `true`.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Indicates whether a document save request is currently pending.
   */
  isSaving: boolean;

  /**
   * Error object that is set when a document save request fails.
   */
  saveError: ErrorType | null;

  /**
   * SWR key used to fetch the current `customBlocks` collection from the API.
   * This key can then be used by SWR's `mutate()` function update cached data to
   * optimistically reflect a changed document while a mutation request is in flight.
   */
  swrKeyCustomBlocks: ReturnType<typeof useReadmeApi>['swrKey'];

  /**
   * Holds reference to the SWR request key that was used to fetch document
   * data from the API. This key can then be used by SWR's `mutate()` function
   * to update cached data to optimistically reflect a changed document while
   * a mutation request is in flight.
   * @see https://swr.vercel.app/docs/mutation
   */
  swrKeyDocument: ReturnType<typeof useReadmeApi>['swrKey'];
}

interface SuperHubDocumentSliceAction {
  /**
   * Creates a new page document inside the provided category.
   * @todo the typing of `newDocument` should be updated to a union of CreateGuideType and
   * CreateReferencePageType once we have the APIV2 typings for the reference section.
   */
  createDocument<CreateDocument extends SuperHubCreateDocumentData>(
    newDocument: CreateDocument,
  ): Promise<CreateDocumentResponse<CreateDocument>['data']>;

  /**
   * Returns a changelog document if one exists and is loaded.
   */
  getChangelogData: () => ReadChangelogType['data'] | null;

  /**
   * Returns a custom page document if one exists and is loaded.
   */
  getCustomPageData: () => CustomPageReadType['data'] | null;

  /**
   * Returns a guides page document if one exists and is loaded.
   */
  getGuidesPageData: () => ReadGuideType['data'] | null;

  /**
   * Returns a reference page document if one exists and is loaded.
   */
  getReferencePageData: () => ReadReferenceType['data'] | null;

  /**
   * Updates the state with data and loading states. Typically called when
   * initializing this slice with SSR data or when hydrating it from an API.
   */
  initialize: (
    payload: Pick<SuperHubDocumentSliceState, 'data' | 'isLoading'> & {
      customBlocks?: SuperHubDocumentSliceState['customBlocks'];
      swrKeyCustomBlocks?: SuperHubDocumentSliceState['swrKeyCustomBlocks'];
      swrKeyDocument?: SuperHubDocumentSliceState['swrKeyDocument'];
    },
  ) => void;

  /**
   * Revalidates the document and pulls down the latest data from the API.
   */
  revalidate: () => Promise<SuperHubDocumentData[] | undefined>;

  /**
   * Create or update a `customBlocks` item in the current state.
   */
  updateCustomBlock: (block: ReadCustomBlockGitType['data']) => void;

  /**
   * Persist changes of the current document to the API.
   */
  updateDocument: (nextDocument: Partial<SuperHubDocumentData>) => Promise<SuperHubDocumentData>;
}

export interface SuperHubDocumentSlice {
  /**
   * State slice containing fields and actions that are relevant when viewing
   * and editing page documents in SuperHub.
   */
  document: SuperHubDocumentSliceAction & SuperHubDocumentSliceState;
}

const initialState: SuperHubDocumentSliceState = {
  customBlocks: [],
  customBlocksHash: {},
  data: null,
  isLoading: false,
  isReady: false,
  isSaving: false,
  saveError: null,
  swrKeyCustomBlocks: null,
  swrKeyDocument: null,
};

/**
 * Creates a state slice containing all fields related to our page document.
 */
export const createSuperHubDocumentSlice: StateCreator<
  SuperHubDocumentSlice & SuperHubStore,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  SuperHubDocumentSlice
> = (set, get) => ({
  document: {
    ...initialState,

    createDocument: async newDocument => {
      const requestUrl = get().getApiEndpoint();
      if (!requestUrl) {
        throw new Error('Missing required request URL');
      }

      set(
        state => {
          state.document.isSaving = true;
        },
        false,
        actionLog('document.createDocument.pending', newDocument),
      );

      try {
        const { data } = await fetcher<CreateDocumentResponse<typeof newDocument>>(requestUrl, {
          body: JSON.stringify(newDocument),
          method: 'POST',
        });

        set(
          state => {
            state.document.data = data;
            state.document.isSaving = false;
          },
          false,
          actionLog('document.createDocument.fulfilled', newDocument),
        );

        // Revalidate the sidebar to include this newly created page.
        get().sidebar.revalidate();
        return data;
      } catch (error) {
        const { info } = error as HTTPError;
        set(
          state => {
            state.document.isSaving = false;
            state.document.saveError = info as ErrorType;
          },
          false,
          actionLog('document.createDocument.rejected', newDocument),
        );
        throw error;
      }
    },

    getChangelogData: () => {
      const data = get()?.document.data;
      return isChangelog(data) ? data : null;
    },

    getCustomPageData: () => {
      const data = get()?.document.data;
      return isCustomPage(data) ? data : null;
    },

    getGuidesPageData: () => {
      const data = get().document.data;
      return isGuidesPage(data) ? data : null;
    },

    getReferencePageData: () => {
      const data = get()?.document.data;
      return isReferencePage(data) ? data : null;
    },

    initialize: payload => {
      const { data, isLoading, swrKeyDocument, customBlocks, swrKeyCustomBlocks } = payload;
      const nextData = {
        data: data ?? get().document.data,
        customBlocks: customBlocks ?? get().document.customBlocks,
        isLoading,
      };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return JSON.stringify(value) !== JSON.stringify(get().document[key]);
      });
      if (!hasChanges) return;

      set(
        state => {
          const writableSwrKeyDocument = (swrKeyDocument ?? get().document.swrKeyDocument) as WritableDeep<
            typeof swrKeyDocument
          >;
          const writableSwrKeyCustomBlocks = (swrKeyCustomBlocks ?? get().document.swrKeyCustomBlocks) as WritableDeep<
            typeof swrKeyCustomBlocks
          >;
          state.document = {
            ...state.document,
            ...nextData,
            swrKeyDocument: writableSwrKeyDocument ?? null,
            swrKeyCustomBlocks: writableSwrKeyCustomBlocks ?? null,
          };

          // After custom blocks is updated, regenerate the hash.
          state.document.customBlocksHash = createCustomBlocksHash(state.document.customBlocks);

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.document.isReady = isClient;

          // When a page is "unrenderable", render the editor in raw mode.
          const isRenderable =
            isGuidesPage(state.document.data) || isReferencePage(state.document.data)
              ? state.document.data.renderable?.status ?? true
              : true;
          state.editor.isRawMode = !isRenderable;
        },
        false,
        actionLog('document.initialize', payload),
      );

      // If custom blocks are present, also initialize RDMD store with them.
      if (!isLoading && customBlocks) {
        rdmdStore.getState().setCustomBlocks(get().document.customBlocksHash);
      }
    },

    revalidate: () => {
      return mutate<SuperHubDocumentData[]>(get().document.swrKeyDocument);
    },

    updateCustomBlock: block => {
      set(
        state => {
          const index = state.document.customBlocks.findIndex(({ tag }) => tag === block.tag);
          if (index === -1) {
            state.document.customBlocks.push(block);
          } else {
            state.document.customBlocks[index] = block;
          }

          // After custom blocks is updated, regenerate the hash.
          state.document.customBlocksHash = createCustomBlocksHash(state.document.customBlocks);
        },
        false,
        actionLog('document.updateCustomBlock', block),
      );

      // Update the page custom blocks SWR cache to the latest changes.
      const { swrKeyCustomBlocks, customBlocks } = get().document;
      mutate<{ data: typeof customBlocks }>(swrKeyCustomBlocks, { data: customBlocks }, { revalidate: false });

      // Re-initialize RDMD store with the updated custom blocks hash.
      rdmdStore.getState().setCustomBlocks(get().document.customBlocksHash);
    },

    updateDocument: async nextDocument => {
      const { data: document } = get().document;
      if (!document) {
        throw new Error('Missing required page document');
      }

      const requestUrl = get().getApiEndpoint(document.slug);
      if (!requestUrl) {
        throw new Error('Missing required request URL');
      }

      set(
        state => {
          state.document.isSaving = true;
        },
        false,
        actionLog('document.updateDocument.pending', nextDocument),
      );

      try {
        const { data } = await fetcher<{ data: SuperHubDocumentData }>(requestUrl, {
          body: JSON.stringify(nextDocument),
          method: 'PATCH',
        });

        set(
          state => {
            state.document.data = data;
            state.document.isSaving = false;
          },
          false,
          actionLog('document.updateDocument.fulfilled', nextDocument),
        );

        // Update page document SWR cache to the latest changes.
        mutate<{ data: typeof data }>(get().document.swrKeyDocument, { data }, { revalidate: false });

        // Revalidate the sidebar to include this newly created page.
        get().sidebar.revalidate();
        return data;
      } catch (error) {
        const { info } = error as HTTPError;
        set(
          state => {
            state.document.isSaving = false;
            state.document.saveError = info as ErrorType;
          },
          false,
          actionLog('document.updateDocument.rejected', nextDocument),
        );
        throw error;
      }
    },
  },
});

export * from './ConnectSuperHubDocumentToApi';
export * from './InitializeSuperHubDocument';
