/**
 * Contains string markers that delimit the beginning and end of where we inject
 * a project's custom CSS styles into the HTML document. We can then use these
 * markers to easily locate where these custom styles are and conditionally
 * toggle them on/off if needed.
 */
export const HubCustomStylesMarker = {
  BEGIN: '/*! BEGIN HUB_CUSTOM_STYLES */',
  END: '/*! END HUB_CUSTOM_STYLES */',
} as const;
