import { useMemo } from 'react';

import { ClassyInstance } from '@core/utils/classy';

type Params = Parameters<typeof ClassyInstance>[0];

/**
 * A utility for generating [className] strings, which
 * supports CSS module auto scoping and BEM expansions.
 */
const useClassy = (scope: Params['classes'], namespace: Params['bem']) => {
  return useMemo(() => {
    return ClassyInstance({
      classes: scope,
      bem: namespace,
    });
  }, [scope, namespace]);
};

export default useClassy;
