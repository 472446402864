/**
 * Converts the provided `customBlocks` collection into a hash representation of
 * block tags to block sources. This data can be used to update RDMX components
 * or RDMD reusable content tags, which is required when parsing a document's
 * markdown body.
 */
export function createCustomBlocksHash<CustomBlocksCollection extends { source: string; tag: string }[]>(
  customBlocks: CustomBlocksCollection,
) {
  return customBlocks.reduce<Record<string, string>>((acc, block) => {
    return {
      ...acc,
      [block.tag]: block.source,
    };
  }, {});
}

export const TutorialTilePlaceholder = `
export const TutorialTilePlaceholder = () => {
  const style = {
    height: "50px",
    border: "1px solid var(--color-border-default)",
    borderRadius: "var(--border-radius-lg)",
    minWidth: "230px",
    display: "inline-flex",
    padding: "10px",
  };

  const placeholderStyle = {
    borderRadius: "var(--border-radius-lg)",
    backgroundColor: "var(--color-skeleton)",
  };

  const avatarStyle = {
    ...placeholderStyle,
    height: "30px",
    width: "30px",
  };

  const lineStyle = {
    ...placeholderStyle,
    height: "12px",
    width: "150px",
    margin: "0 15px",
  };

  return (
    <div>
      <div style={style}>
        <div style={avatarStyle} />
        <div>
            <div style={{ ...lineStyle, marginBottom: "6px" }} />
            <div style={{ ...lineStyle, width: "75px" }} />
        </div>
      </div>
    </div>
  );
}

<TutorialTilePlaceholder />
`;
