import type { OASDocument, HttpMethods } from 'oas/types';

import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { actionLog, createBoundedUseStore, isClient } from '@core/store/util';

import type { CurrentOperation } from '@ui/APIDesigner/OperationEditor';

import { createRequestExampleEditorSlice, type RequestExampleEditorSlice } from './RequestExampleEditor';

interface APIDesignerStoreState {
  /**
   * Indicates whether the reference store is fully hydrated with incoming data
   * either from our SSR props, API endpoint or some other call to reset() that
   * moves us out of our "default" starting state.
   */
  isReady: boolean;

  /**
   * The current OpenAPI definition.
   */
  oas?: OASDocument;

  /**
   * The currently selected OpenAPI operation.
   */
  operation?: CurrentOperation;
}

interface APIDesignerStoreActions {
  /**
   * Returns the operation on the OAS file for the current path and method
   */
  getCurrentOperation: () => { currentMethod: string; currentPath: string; operation };

  /**
   * Initializes reference store. Used by `InitializeAPIDesignerStore`.
   */
  initialize: (opts: { oas: OASDocument; operation: CurrentOperation }) => void;

  /**
   * Resets state back to the last "initialized" state. When `partialState` is
   * provided, it is merged into state and re-initialized to this.
   */
  reset: () => void;

  /**
   * Updates the current OpenAPI definition.
   */
  setOas: (oas: OASDocument) => void;

  /**
   * Updates the current OpenAPI operation.
   */
  setOperation: (operation: CurrentOperation) => void;
}

export type APIDesignerStore = APIDesignerStoreActions & APIDesignerStoreState & RequestExampleEditorSlice;

const initialState: APIDesignerStoreState = {
  isReady: false,
};

export const apiDesignerStore = createStore<APIDesignerStore>()(
  devtools(
    immer((set, get, ...props) => {
      /**
       * Holds reference to the initial state so we can support resetting the
       * store back to this state when calling `reset()`.
       */
      const resetState = {
        ...initialState,
        ...createRequestExampleEditorSlice(set, get, ...props),
      };

      return {
        ...resetState,

        initialize: ({ oas, operation }) => {
          get().setOas(oas);
          get().setOperation(operation);
          get().requestExampleEditor.initialize();

          set(
            state => {
              // When running on the server, we must avoid marking this store
              // as "ready" to ensure it continues receiving updates until it
              // gets initialized on the client's first render.
              if (isClient) {
                state.isReady = true;
              }
            },
            false,
            actionLog('initialize'),
          );
        },

        getCurrentOperation: () => {
          const oas = get().oas;
          const currentPath = get().operation?.path as string;
          const currentMethod = get().operation?.method as HttpMethods;
          const operation = oas?.paths?.[currentPath]?.[currentMethod];

          return { currentMethod, currentPath, operation };
        },

        reset: () => {
          set(resetState, false, actionLog('reset'));
        },

        setOas: oas => {
          set(
            state => {
              state.oas = oas;
            },
            false,
            actionLog('setOas', { oas }),
          );
        },

        setOperation: operation => {
          set(
            state => {
              state.operation = operation;
            },
            false,
            actionLog('setOperation', { operation }),
          );
        },
      };
    }),
    { name: 'APIDesignerStore' },
  ),
);

export const useAPIDesignerStore = createBoundedUseStore(apiDesignerStore);

export * from './InitializeAPIDesignerStore';
