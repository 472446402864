import type { SuperHubDocumentData } from '.';
import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';

import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import { useSuperHubStore } from '..';

interface InitializeSuperHubDocumentProps {
  children: React.ReactNode;

  customBlocks?: ReadCustomBlockGitCollectionType['data'];

  /**
   * Page document that is typically sourced from route data or fetched directly
   * from the v2 API endpoint.
   */
  document: SuperHubDocumentData | null;
}

/**
 * Initializes the SuperHub store's document slice with incoming route data and
 * configures various state fields based on that data. Use this to initially
 * hydrate the store just once typically from SSR props. This allows components
 * connected to the store's `document` data to render server-side.
 * @example
 * <InitializeSuperHubStoreDocument document={ssrProps.document}>
 *   ...
 * </InitializeSuperHubStoreDocument>
 */
export function InitializeSuperHubDocument({ children, customBlocks, document }: InitializeSuperHubDocumentProps) {
  const { isServer } = useEnvInfo();
  const [initialize, isReady] = useSuperHubStore(s => [s.document.initialize, s.document.isReady]);

  // To enable SSR, we must call this inline rather than inside an effect. On
  // the server, ignore the "ready" state b/c our global stores on the server
  // persist indefinitely and won't ever reset the "ready" state to false.
  if (isServer || !isReady) {
    initialize({
      data: document,
      customBlocks,
      isLoading: false,
    });
  }

  return <>{children}</>;
}
