import type { OASDocument } from 'oas/types';

import React, { useEffect } from 'react';

import type { CurrentOperation } from '@ui/APIDesigner/OperationEditor';

import { useAPIDesignerStore } from '.';

interface InitializeAPIDesignerStoreProps {
  children: React.ReactNode;
  oas: OASDocument;
  operation: CurrentOperation;
}

export function InitializeAPIDesignerStore({ children, oas, operation }: InitializeAPIDesignerStoreProps) {
  const [isReady, initialize] = useAPIDesignerStore(s => [s.isReady, s.initialize]);

  if (!isReady) {
    initialize({ oas, operation });
  }

  useEffect(() => {
    initialize({ oas, operation });
  }, [initialize, oas, operation]);

  return <>{children}</>;
}
