/**
 * Validates whether a URL is a valid URL.
 *
 * Polyfill for URL.canParse to support slightly outdated browsers.
 */
export const isValidUrl = (url: URL | string): boolean => {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
};
