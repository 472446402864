import type { ReadCustomBlockGitCollectionType } from '@readme/api/src/mappings/customblock/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useReadmeApi from '@core/hooks/useReadmeApi';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { useSuperHubStore } from '..';

interface ConnectSuperHubDocumentToApiProps {
  children: React.ReactNode;
  /**
   * Whether SWR should revalidate page data on focus. Default is `false`.
   */
  revalidateOnFocus?: boolean;
}

/**
 * Connects our SuperHub store's document slice to the page API endpoint via SWR
 * based on the current route section and slug. Fetches new data whenever
 * rendered and updates the store.
 */
export function ConnectSuperHubDocumentToApi({
  children,
  revalidateOnFocus = false,
}: ConnectSuperHubDocumentToApiProps) {
  const { section, slug } = useParams<SuperHubRouteParams>();
  const [getApiEndpoint, initialize] = useSuperHubStore(s => [s.getApiEndpoint, s.document.initialize]);

  const apiUrl = slug ? getApiEndpoint(slug) : null;
  const {
    data: documentData,
    isLoading: isDocumentLoading,
    swrKey: swrKeyDocument,
  } = useReadmeApi<ReadGuideType>(apiUrl, {
    swr: {
      revalidateOnFocus,
      shouldRetryOnError: true,
    },
  });

  // Temporarily limit custom block data fetching to only the docs and reference sections.
  // We'll want to load custom blocks for custom pages and changelogs once there's API support.
  const isCustomBlockRoute = ['docs', 'reference'].includes(section || '');

  const {
    data: customBlocksData,
    isLoading: isCustomBlocksLoading,
    swrKey: swrKeyCustomBlocks,
  } = useReadmeApi<ReadCustomBlockGitCollectionType>(
    apiUrl && isCustomBlockRoute ? `${apiUrl.replace('?fullOAS=true', '')}/custom_blocks` : null, // TODO-Marc: this is a hack to get the correct custom block url without the fullOAS query param
    {
      swr: {
        revalidateOnFocus,
        shouldRetryOnError: true,
      },
    },
  );

  /**
   * Indicates whether data is loading or not. Because we require multiple API
   * fetches to hydrate document data, we must evalute multiple loading states.
   */
  const isLoading = isDocumentLoading || isCustomBlocksLoading;

  /**
   * Indicates whether both document and custom blocks hydration is synced up.
   * This is important b/c we only want to update our store after both request
   * states are either pending or copmleted. This is equivalent to a
   * `Promise.all(documentFetch, customBlocksFetch).`
   */
  const isLoadingStateStable = isDocumentLoading === isCustomBlocksLoading;

  useEffect(() => {
    if (isLoadingStateStable) {
      initialize({
        customBlocks: customBlocksData?.data || [],
        data: documentData?.data || null,
        isLoading,
        swrKeyCustomBlocks,
        swrKeyDocument,
      });
    }
  }, [
    customBlocksData?.data,
    documentData?.data,
    initialize,
    isLoading,
    isLoadingStateStable,
    swrKeyCustomBlocks,
    swrKeyDocument,
  ]);

  return <>{children}</>;
}
