import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { SidebarCategory } from '@readme/iso';

import { CATEGORY_URI_REGEX } from '@readme/api/src/core/constants';
import { README_CONFIG } from '@readme/iso';
import produce from 'immer';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

/**
 * Given a parent URI, return the URI of the parent page or category.
 * If the parent uri provided is 1) a parent page and 2) a path like page/subpage,
 * We need to just get the direct parent and discard the grandparent
 *
 * @example
 * getPageParentURI("/versions/1.0/categories/guides/category")
 *    => "/versions/1.0/categories/guides/category"
 * getPageParentURI("/versions/1.0/guides/parent")
 *    => "/versions/1.0/guides/parent"
 * getPageParentURI("/versions/1.0/guides/grandparent/parent")
 *    => "/versions/1.0/guides/parent"
 *
 * TODO: currently page.parent can be a path like grandparent/parent (RM-9831)
 *
 */
export function getPageParentURI(parentUri: string) {
  const segments = parentUri.split('/');
  let parentURIParts: string[] = [];

  // Split at 'guides' or 'reference' to get rid of the generic version/section/etc info
  const generalInfoEnds = segments.findIndex(seg => ['guides', 'reference', 'custom_pages'].includes(seg)) + 1;
  // Leaving us just the path(s) for the parent pages/category
  const pathInfo = segments.slice(generalInfoEnds);
  if (pathInfo.length > 1) {
    const generalInfo = segments.slice(0, generalInfoEnds);
    parentURIParts = parentURIParts.concat(generalInfo, pathInfo[pathInfo.length - 1]);
  } else {
    parentURIParts = segments;
  }

  return parentURIParts.join('/');
}

/**
 * Given a category URI, return the category's title and section.
 *
 * This is almost the same as a backend implementation of this function
 * with some additional logic to handle the custom_pages section. Not sure why.
 *
 * @see {@link https://github.com/readmeio/readme/blob/e930e4f4666078a2743370812cdd309c4fbe861d/packages/api/src/mappings/category/util.ts#L16-L26}
 * @see {@link https://readmeio.slack.com/archives/C06KVAYKBK8/p1730472400937719}
 */
export const getCategoryFromURI = (uri: string) => {
  const segments = uri.split('/categories/');
  if (!segments.length || segments.length <= 1 || !segments[1].includes('/')) {
    if (segments[1] === 'custom_pages') return { section: 'custom_pages', title: '' };
    return undefined;
  }
  const { section, slug } = CATEGORY_URI_REGEX.parse(uri)!;

  return {
    title: slug,
    section: section === 'guides' ? 'docs' : section,
  };
};

/**
 * Recursively iterates through the provided list of sidebar categories and
 * returns the first Category or Page entity it finds with a matching `uri`
 * field. Returns `undefined` if no match was found.
 */
export function findEntityByURI(
  entities: (GitSidebarCategory | GitSidebarPage)[] = [],
  uri: string,
): GitSidebarCategory | GitSidebarPage | undefined {
  let result: ReturnType<typeof findEntityByURI>;
  for (let n = 0; n < entities.length; n += 1) {
    const entity = entities[n];
    if (entity.uri === uri) {
      result = entity;
    } else {
      result = findEntityByURI(entity.pages, uri);
    }
    if (result) break;
  }
  return result;
}

/**
 * Returns whether the provided category is an `api_config` category (for API Reference configuration pages) or not
 */
export function isApiConfigCategory(
  routeSection: NonNullable<SuperHubRouteParams['section']> | null,
  categoryTitle: string,
  categoryPages: GitSidebarCategory['pages'] | SidebarCategory['pages'],
) {
  return (
    routeSection === 'reference' &&
    categoryTitle === README_CONFIG &&
    categoryPages.every(page => page.type === 'api_config')
  );
}

/**
 * Maintains hash maps for O(1) lookups for sidebar categories and pages.
 * @example
 * ```ts
 * const sidebarMap = new SidebarMap();
 * sidebarMap.update(categories);
 *
 * const category = !!sidebarMap.categories[title];
 * const page = !!sidebarMap.pages[slug];
 * ```
 */
export class SidebarMap {
  categories: Record<string, GitSidebarCategory> = {};

  pages: Record<string, GitSidebarPage> = {};

  /**
   * Updates the hash map based on the new provided data.
   */
  update(data: GitSidebarCategory[]) {
    const { categories, pages } = SidebarMap.toHash(data);
    this.categories = categories;
    this.pages = pages;
  }

  /**
   * Recursively iterates through a list of git category or page objects and all
   * its child pages to return a flat hash of objects that are associated to
   * some key value. The hash key used for categories is `title`. For pages,
   * `slug` is used.
   *
   * Useful for generating a flat hash record that is then referenced for fast
   * O(1) lookups to any category or page object based on the category `title` or
   * page `slug`.
   * @example
   * ```ts
   * const categoryByTitle = toHash(categories);
   * const hotDiggityDog = categoryByTitle['Hot Dog'];
   *
   * const pageBySlug = toHash(categories);
   * const shadyPage = pageBySlug['shady'];
   * ```
   */
  static toHash(list: (GitSidebarCategory | GitSidebarPage)[] = []): {
    categories: Record<string, GitSidebarCategory>;
    pages: Record<string, GitSidebarPage>;
  } {
    return list.reduce(
      (hash, model) => {
        const childrenHash = SidebarMap.toHash(model.pages);

        return produce(hash, draft => {
          if ('slug' in model) {
            draft.pages[model.slug] = model;
          } else {
            draft.categories[model.title.toLowerCase()] = model;
          }
          Object.assign(draft.pages, childrenHash.pages);
        });
      },
      { categories: {}, pages: {} },
    );
  }
}

/**
 * Traverses the sidebar tree and returns the total number of pages that has its
 * `renderable.status` flag set to `false`.
 */
export function getTotalUnrenderable(categories: GitSidebarCategory[]) {
  function countUnrenderable(pages: GitSidebarPage[], total: number) {
    return pages.reduce((count, page) => {
      if (page.renderable?.status === false) {
        return countUnrenderable(page.pages, count + 1);
      }
      return countUnrenderable(page.pages, count);
    }, total);
  }

  return categories.reduce((count, category) => {
    return countUnrenderable(category.pages, count);
  }, 0);
}
