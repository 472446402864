import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function scrollToTop(isSmoothScroll = false) {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: isSmoothScroll ? 'smooth' : 'auto',
  });
}

export default function ScrollTop({ smooth = false, deps }: { deps?: string[] | undefined; smooth?: boolean }) {
  const { pathname } = useLocation();
  // eslint-disable-next-line no-param-reassign
  deps = Array.isArray(deps) ? deps : [pathname];

  useEffect(() => {
    // Check for text fragments in URL hash, see below for more reading:
    // https://web.dev/text-fragments
    let textFragmentHash = '';
    try {
      const performanceName = performance.getEntries().find(({ entryType }) => entryType === 'navigation')?.name;

      if (performanceName) {
        textFragmentHash = new URL(performanceName).hash;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    // Don't scroll if the URL contains a hash parameter,
    // or if the window scroll position is already the top
    if (window.location.hash || textFragmentHash || window.scrollY === 0) return;
    scrollToTop(smooth);
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}
