import type { DataForHAR } from 'oas/types';

// eslint-disable-next-line readme-internal/no-restricted-imports
import { maskCredential } from '@readme/server-shared/metrics/mask-credential';
import { useCallback, useEffect } from 'react';

import useLocalStorage from '@core/hooks/useLocalStorage';
import { useReferenceStore } from '@core/store';
import type { ReferenceAuthSliceState } from '@core/store/Reference/Auth';

const LOCALSTORAGE_PREFIX = 'reference';

const LOCALSTORAGE_KEY = 'auth';

/**
 * `useLocalStorage` `opts` parameter type
 */
type UseLocalStorageOpts = NonNullable<Parameters<typeof useLocalStorage>[0]>;

/**
 * Auth data as it's stored in local storage
 */
type StoredAuth = Partial<ReferenceAuthSliceState & { servers: DataForHAR['server'] }> | undefined;

/**
 * The wrapper initializes an encrypted instance of `useLocalStorage()`,
 * as well as a custom getter and setter function. This is used for getting/setting
 * API authentication-related data in local storage.
 */
export default function useAuthStorage(
  /**
   * An optional callback function that will be called whenever the local storage data changes.
   * This function will only be called when a separate window changes the local storage data,
   * due to how the `storage` JS event works.
   */
  onChange?: NonNullable<UseLocalStorageOpts['onChange']>['handler'],
) {
  const storageOpts: UseLocalStorageOpts = {
    prefix: LOCALSTORAGE_PREFIX,
    encrypt: true,
  };

  if (onChange) {
    storageOpts.onChange = { handler: onChange, key: LOCALSTORAGE_KEY };
  }

  const storage = useLocalStorage(storageOpts);

  const getStoredAuth = useCallback(() => {
    // If the user has reference auth credentials present in within our localstorage system, we
    // should try to preload that data into their current context. If they don't have any of these
    // we should do our best to load some defaults from the API definition that they're currently
    // looking at.
    let storedAuth: StoredAuth;
    try {
      const rawStoredAuth = storage.getItem(LOCALSTORAGE_KEY);
      storedAuth = JSON.parse(rawStoredAuth);
      if (typeof storedAuth !== 'object' || storedAuth === null) {
        throw new TypeError('Stored auth is not valid.');
      }
    } catch (err) {
      // If we can't retrieve our auth data out of storage then it's likely corrupted and we
      // should wipe it. This will usually only happen within unit tests that don't clean up
      // after themselves.
      storage.removeItem(LOCALSTORAGE_KEY);
    }

    return storedAuth;
  }, [storage]);

  const updateStoredAuth = useCallback(
    (
      authToUpdate: ReferenceAuthSliceState['auth'],
      groupIdToUpdate: ReferenceAuthSliceState['group'],
      oauthToUpdate: ReferenceAuthSliceState['oauth'],
      selectedAuthToUpdate: ReferenceAuthSliceState['selectedAuth'],
      serversToUpdate: DataForHAR['server'],
    ) => {
      const storedData: StoredAuth = {
        auth: { ...authToUpdate },
        group: groupIdToUpdate,
        hashedGroup: maskCredential(groupIdToUpdate),
        oauth: { ...oauthToUpdate },
        selectedAuth: selectedAuthToUpdate,
        servers: serversToUpdate,
      };

      // We should only set auth data into storage if there's auth, so if they happened to remove the
      // auth credential for a given security scheme we shouldn't store an empty token.
      Object.keys(storedData.auth || {}).forEach(k => {
        const currentAuth = storedData.auth?.[k];
        if (typeof currentAuth === 'object' && currentAuth !== null) {
          if (
            (currentAuth?.user === null || !currentAuth?.user?.length) &&
            (currentAuth?.pass === null || !currentAuth?.pass?.length)
          ) {
            if (storedData.auth) delete storedData.auth[k];
          }
        } else if (currentAuth === null || (typeof currentAuth !== 'number' && !currentAuth?.length)) {
          if (storedData.auth) delete storedData.auth[k];
        }
      });

      if (!Object.keys(storedData.auth || {}).length) {
        delete storedData.auth;
      }

      if (!storedData.group) {
        delete storedData.group;
        delete storedData.hashedGroup;
      }

      Object.keys(storedData.oauth || {}).forEach(k => {
        const currentScheme = storedData.oauth?.[k];
        if (currentScheme === null || typeof currentScheme !== 'object') {
          if (storedData.oauth) delete storedData.oauth[k];
        }
      });

      if (!storedData.servers) {
        delete storedData.servers;
      }

      if (!storedData.selectedAuth || !storedData.selectedAuth.length) {
        delete storedData.selectedAuth;
      }

      if (Object.keys(storedData).length) {
        try {
          storage.setItem('auth', JSON.stringify(storedData));
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(
            'Something when wrong when attempting to stringify the following reference auth data:',
            storedData,
          );
          storage.removeItem(LOCALSTORAGE_KEY);
        }
      } else {
        storage.removeItem(LOCALSTORAGE_KEY);
      }
    },
    [storage],
  );

  return { storage, getStoredAuth, updateStoredAuth };
}

/**
 * Persists any zustand auth storage changes to the encrypted local storage.
 */
export function usePersistAuthStorage() {
  const { updateStoredAuth } = useAuthStorage();

  const [auth, group, oauth, selectedAuth, server] = useReferenceStore(s => [
    s.auth.auth,
    s.auth.group,
    s.auth.oauth,
    s.auth.selectedAuth,
    s.form.schemaEditor.data.server,
  ]);

  useEffect(() => {
    updateStoredAuth(auth, group, oauth, selectedAuth, server);
  }, [auth, group, oauth, selectedAuth, server, updateStoredAuth]);
}
