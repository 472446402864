import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

import classes from './style.module.scss';

const DeprecationWarning = ({ currentVersion, lang, project }) => {
  const { pathname } = useLocation();
  const { siblings, stable, subdomain } = project;

  const origin = typeof document !== 'undefined' ? new URL(document.location).origin : '';

  // When switching to the active version, we return the user to the topmost feature path they're on.
  // E.g. if a user is at /v2/docs/test-page, switching to the active version will take them to /docs.
  const subdomainPath = siblings ? `/${subdomain}` : '';
  const langPath = lang !== 'en' ? `/lang-${lang}` : '';
  // @xxx: Using a malicious URL such as "readme.com/v1/%5cevil" will result in
  // `featurePath` being "/evil". Which will result in the anchor's href
  // resolving to https://evil. Uh oh! So let's just ignore any potentially
  // malicious looking strings.
  const featurePath = pathname.split('/')[1]?.replace(/^\\.*$/, '') || '';
  const stableVersionUrl = `${origin}${subdomainPath}${langPath}/${featurePath}`;

  return (
    <a className={classes.DeprecationWarning} href={stableVersionUrl}>
      These docs are for v{currentVersion}. Click to read the latest docs for v{stable.version}.
    </a>
  );
};

DeprecationWarning.propTypes = {
  currentVersion: PropTypes.string,
  lang: PropTypes.string,
  project: PropTypes.object,
};

export default DeprecationWarning;
