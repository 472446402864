import { useMemo } from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

function useTimezone() {
  const { isClient } = useEnvInfo();

  return useMemo(() => {
    if (!isClient) return 'UTC';

    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, [isClient]);
}

export default useTimezone;
