import Cookie from 'js-cookie';

import type { HTTPError } from '@core/utils/types/errors';

async function fetcher<T>(
  url: Parameters<typeof fetch>[0],
  options: Parameters<typeof fetch>[1] & { isFormData?: boolean },
): Promise<T> {
  const { headers = {}, isFormData = false, ...rest } = options || {};

  const requestHeaders: HeadersInit = {
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
    ...headers,
  };

  // When sending multipart/form-data, we need to remove the default Content-Type header
  if (isFormData && 'Content-Type' in requestHeaders) {
    delete requestHeaders['Content-Type'];
  }

  const res = await fetch(url, {
    headers: requestHeaders,
    ...rest,
  });

  const contentType = res.headers.get('Content-Type');
  const isJsonResponse = ['application/json', 'application/problem+json'].some(type => contentType?.includes(type));
  const response = isJsonResponse ? await res.json() : await res.text();

  // Handle unsuccessful responses by rejecting the fetch with a general error
  // object augmented with additional metadata about the status and request.
  if (!res.ok) {
    const error: HTTPError = new Error(`An error occurred while fetching "${url}".`);
    error.info = response;
    error.status = res.status;
    error.statusText = res.statusText;
    error.url = url;
    throw error;
  }

  return response as T;
}

export default fetcher;
