import loadable from '@loadable/component';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const SuggestionDiff = loadable(() => import('./Diff'), { ssr: true });
const SuggestionsEditor = loadable(() => import('./Editor'), { ssr: true });
const SuggestionsList = loadable(() => import('./List'), { ssr: true });

const SuggestionsRouter = props => {
  return (
    <Switch>
      <Route exact path="/suggested-edits/:slug">
        <SuggestionDiff {...props} />
      </Route>
      <Route path="/suggested-edits">
        <SuggestionsList {...props} />
      </Route>
      <Route path="/edit/:slug?">
        <SuggestionsEditor {...props} />
      </Route>
    </Switch>
  );
};

SuggestionsRouter.propTypes = {};

export default SuggestionsRouter;
