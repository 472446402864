import type { ReferenceLanguageSlice, ReferenceLanguageSliceState } from './types';
import type { ReferenceStore } from '..';
import type { StateCreator } from 'zustand';

import { actionLog } from '@core/store/util';

import {
  getAvailableLibraries,
  getDefaultLanguage,
  getDefaultLanguages,
  getDefaultLibraryForLanguage,
  isCustomSampleSelected,
} from './helpers';

export const initialState: ReferenceLanguageSliceState = {
  availableLanguageLibraries: [],
  availableLanguages: [],
  customCodeSamples: false,
  defaultLanguages: [],
  isCustomSampleSelected: false,
  languageLibrary: null,
  maxLanguages: 5,
  supportsSimpleMode: false,
  useAllAvailableLanguages: true,
};

/**
 * Language state slice containing fields related to the language/library/request code sample picker
 */
export const createReferenceLanguageSlice: StateCreator<
  ReferenceLanguageSlice & ReferenceStore,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  ReferenceLanguageSlice
> = (set, get) => ({
  language: {
    ...initialState,

    updateDefaultLanguages: defaultLanguages => {
      set(
        state => {
          state.language.defaultLanguages = defaultLanguages;
        },
        false,
        actionLog('updateDefaults', defaultLanguages),
      );
    },

    updateLanguage: (language, library = null) => {
      const availableLanguageLibraries = getAvailableLibraries(
        get().language.customCodeSamples,
        language,
        get().language.supportsSimpleMode,
      );

      let languageLibrary = library;
      if (!languageLibrary) {
        // if there isn't a language library passed, use the default
        languageLibrary = getDefaultLibraryForLanguage(
          get().language.customCodeSamples,
          language,
          availableLanguageLibraries,
          get().language.supportsSimpleMode,
        );
      }

      set(
        state => {
          state.language = {
            ...state.language,
            availableLanguageLibraries,
            isCustomSampleSelected: isCustomSampleSelected(get().language.customCodeSamples, language),
            language,
            languageLibrary,
          };
        },
        false,
        actionLog('updateLanguage', { language, library }),
      );
    },

    initialize: data => {
      const {
        availableLanguages,
        customCodeSamples,
        language,
        maxLanguages,
        providedLanguages,
        supportsSimpleMode,
        useAllAvailableLanguages,
      } = data;
      set(
        state => {
          const defaultLanguages = getDefaultLanguages(availableLanguages, providedLanguages, maxLanguages);
          // language might be empty/undefined, so this function ensures it's populated with something
          const defaultLanguage = getDefaultLanguage(defaultLanguages, language, useAllAvailableLanguages);
          const availableLanguageLibraries = getAvailableLibraries(
            customCodeSamples,
            defaultLanguage,
            supportsSimpleMode,
          );
          const languageLibrary = getDefaultLibraryForLanguage(
            customCodeSamples,
            defaultLanguage,
            availableLanguageLibraries,
            supportsSimpleMode,
          );

          state.language = {
            ...state.language,
            availableLanguages,
            availableLanguageLibraries,
            customCodeSamples,
            defaultLanguages,
            isCustomSampleSelected: isCustomSampleSelected(customCodeSamples, defaultLanguage),
            language: defaultLanguage,
            languageLibrary,
            maxLanguages,
            supportsSimpleMode,
            useAllAvailableLanguages,
          };
        },
        false,
        actionLog('initialize language slice', data),
      );
    },
  },
});
