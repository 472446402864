import debug from 'debug';
import { BrowserRouter } from 'react-router-dom';

const debugRouter = debug('readme:router');

/**
 * A drop-in replacement for `react-router-dom`'s `BrowserRouter` that
 * logs `debug` statements to your browser console.
 */
class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    debugRouter('initial BrowserRouter props:', props);
    debugRouter('initial history', this.history);
    this.history.listen((location, action) => {
      debugRouter(`The current URL is ${location.pathname}${location.search}${location.hash}`);
      debugRouter(`The last navigation action was ${action}`, this.history);
    });
  }
}

export default DebugRouter;
