import type { ProjectState } from '.';

import React from 'react';

import { useProjectStore } from '.';

export interface InitializeProjectStoreProps {
  children: React.ReactNode;

  /**
   * Initial project store data that represents the starting state for our
   * project store. This data is typically sourced from our SSR props at the
   * root App level.
   */
  data: ProjectState;
}

/**
 * Initializes the ProjectStore with the provided project data just once on the
 * initial render and never again. Use this to initially hydrate the store from
 * SSR props.
 * @example
 * <InitializeProjectStore data={initialProjectData}>
 *   ...
 *</InitializeProjectStore>
 */
export function InitializeProjectStore({ children, data }: InitializeProjectStoreProps) {
  const [isReady, reset] = useProjectStore(s => [s.isReady, s.reset]);

  // To prevent the store from ever rendering on the client with unininitialized
  // mock data, we can't do this inside an effect. Instead, we must initialize
  // the store inline but only once before the first mount and never again.
  if (data && !isReady) {
    reset(data);
  }

  return <>{children}</>;
}
