import { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

export type IdGenerator = (segment?: string) => string;

/**
 * Returns a bound function that when invoked returns a unique string based on
 * a required namespace and segment name. Every invocation of the bound function
 * returns a consistent base UUID combined with the namespace and segment.
 *
 * @example
 * const uid = useUniqueId('MyComponent')
 * uid('segment') => 'MyComponent-segment-UUID'
 * uid('segment-two') => 'MyComponent-segment-two-UUID'
 */
function useUniqueId(namespace: string) {
  // To stablize the ID between server and client, we must ensure the IDs match
  // only between the server-side render and the very first client-side render.
  // In those two cases, our default ID serves as a static string placeholder,
  // which is replaced by subsequent renders with the UUID.
  const [id, setId] = useState('static-id-placeholder');

  // After initial render, immediately begin using UUIDs.
  useEffect(() => {
    setId(uuid());
  }, []);

  const func = useCallback<IdGenerator>(
    segment => {
      const ret = [id];
      if (segment) ret.unshift(segment);
      ret.unshift(namespace);
      return ret.join('-');
    },
    [id, namespace],
  );

  return func;
}

export default useUniqueId;
