import UrlManager from '@readme/server-shared/UrlManager'; // eslint-disable-line readme-internal/no-restricted-imports
import { SelectedAppContext } from '@readme/variable';
import PropTypes from 'prop-types';
import React, { createContext, useMemo, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';

import { RdmdStoreInitializer } from '@core/store';
import LabsContext from '@core/utils/Labs/context';

import ProjectState, { ProjectContext } from './project';

const {
  utils: { BaseUrlContext, GlossaryContext, VariablesContext },
} = require('@readme/markdown');

// TODO: Move this into each respective @readme/* repo instead. The exported
// context objects should have their display names already set.
SelectedAppContext.displayName = 'SelectedAppContext';
BaseUrlContext.displayName = 'BaseUrlContext';
GlossaryContext.displayName = 'GlossaryContext';
VariablesContext.displayName = 'VariablesContext';

const defaultUserContext = {
  user: false,
  permissions: [],
  mutateUser: () => {},
};
const UserContext = createContext(defaultUserContext);
UserContext.displayName = 'UserContext';

const VersionContext = createContext({ version: '' });
VersionContext.displayName = 'VersionContext';

const APIBaseUrlContext = createContext('/');
APIBaseUrlContext.displayName = 'APIBaseUrlContext';

const LocalizationContext = createContext('en');
LocalizationContext.displayName = 'LocalizationContext';

const EnterpriseParentContext = createContext({
  // in the hub, this will only be true on the GLP
  isParent: false,
  // does the group have singleProjectEnterprise on?
  singleParent: false,
  // the global landing page HTML content
  lpMarkup: '',
});
EnterpriseParentContext.displayName = 'EnterpriseParentContext';

const defaultAppMetaContext = {
  baseUrl: '/',
  robots: '',
  metaTitle: '',
  title: '',
  type: '',
  keywords: '',
};

const AppMetaContext = createContext({
  ...defaultAppMetaContext,
  updateAppMeta: () => {},
});
AppMetaContext.displayName = 'AppMetaContext';

/**
 * @see `config.get('client.configAllowList')`
 */
const ConfigContext = createContext({});
ConfigContext.displayName = 'ConfigContext';

/**
 * Global SWR fetcher that normalizes requests to our API endpoints. It always
 * attempts to resolve successful responses with JSON data. Otherwise, it
 * returns a rejected Promise with an Error object that contains some metadata
 * about the failed request.
 * @param {string} url
 * @return {Promise}
 */
async function fetcher(url) {
  const response = await fetch(url);

  // Reject the fetch with a general error object if the response was
  // unsuccessful with any status code outside the range of 200-299.
  if (!response.ok) {
    const error = new Error(`An error occurred while fetching "${url}"`);
    error.status = response.status;
    error.statusText = response.statusText;
    error.url = url;
    throw error;
  }

  return response.json();
}

export const makeEnterpriseParentState = (project, lpMarkup, isDetachedProductionSite) => {
  const isSPEGroup = !!project?.flags?.singleProjectEnterprise;
  const groupChildren = project?.childrenProjects || [];
  const stagingEnv = !project.flags?.staging ? 'off' : !isDetachedProductionSite ? 'stage' : 'prod';
  return {
    children: groupChildren,
    isParent: groupChildren.length || isSPEGroup,
    lpMarkup,
    singleParent: isSPEGroup,
    stagingEnv,
  };
};

const AppContext = ({
  apiBaseUrl,
  baseUrl,
  config,
  context,
  children,
  enterpriseLP,
  isDetachedProductionSite,
  language,
  name,
  meta: propMeta = {},
  rdmd = {},
}) => {
  const [selected, changeSelected] = useState('');
  const { project, variables, terms, version, labs } = context;
  const location = useLocation();
  const isSuggestedEditRoute = /^\/edit|suggested-edits/.test(location.pathname);

  const [user, setUser] = useState({
    ...context.user,
    mutateUser: next =>
      setUser(state => {
        next.mutateUser = state.mutateUser;
        if (JSON.stringify(state) !== JSON.stringify(next)) return next;
        return state;
      }),
  });

  const [meta, setMeta] = useState({
    ...defaultAppMetaContext,
    ...propMeta,
    updateAppMeta: nextState =>
      setMeta(state => {
        nextState.updateAppMeta = state.updateAppMeta;
        if (JSON.stringify(state) !== JSON.stringify(nextState)) return nextState;
        return state;
      }),
  });

  // Extend the config object with the app name
  // Useful to know which app is currently running for components that are shared between apps
  const configValue = useMemo(() => {
    return {
      ...config,
      name,
    };
  }, [config, name]);

  /**
   * Indicates whether this particular route should be visible to search index
   * crawlers or not. Prevents indexing all suggested edit routes. Otherwise,
   * disable indexing on pages that are "hidden" or has explicitly turned off
   * indexing in their SEO metadata.
   */
  const disableCrawlers = !!meta.hidden || meta.robots === 'noindex' || isSuggestedEditRoute;
  const [enterpriseParent] = useState(makeEnterpriseParentState(project, enterpriseLP, isDetachedProductionSite));

  /**
   * This <title> value should match the same logic as the <title> tag in
   * server-side rendering otherwise the react-meta-tags here can override things incorrectly.
   *
   * @see packages/hub/views/includes/head.jade
   */
  const titleTag =
    project.seo?.overwrite_title_tag && meta.metaTitle ? meta.metaTitle : meta.title || project.name || 'Loading…';

  // Info for meta tags
  const description = meta.description ?? project.description;
  const title = meta.metaTitle || meta.title || project.name;

  return (
    <ConfigContext.Provider value={configValue}>
      <BaseUrlContext.Provider value={baseUrl}>
        <APIBaseUrlContext.Provider value={apiBaseUrl}>
          <LocalizationContext.Provider value={language}>
            <VariablesContext.Provider value={variables}>
              <GlossaryContext.Provider value={terms}>
                <ProjectState value={project}>
                  <VersionContext.Provider value={version}>
                    <UserContext.Provider value={user}>
                      <AppMetaContext.Provider value={meta}>
                        <SelectedAppContext.Provider value={{ selected, changeSelected }}>
                          <EnterpriseParentContext.Provider value={enterpriseParent}>
                            <LabsContext.Provider value={labs}>
                              <MetaTags>
                                {/* Note: Don't conditionally show tags, because
                              <MetaTags> won't remove them! Grr! */}

                                <title>{titleTag}</title>
                                <meta content="width=device-width, initial-scale=1" name="viewport" />
                                <meta content={description} name="description" />
                                <meta content={title} property="og:title" />
                                <meta content={description} property="og:description" />
                                <meta content={meta.image?.[0]} property="og:image" />
                                <meta content={title} property="twitter:title" />
                                <meta content={description} property="twitter:description" />
                                <meta content={meta.keywords} property="keywords" />

                                {/* Okay I don't love this, because `index` isn't a real thing.
                              But we can't conditionally show this tag using MetaTags, because
                              MetaTags won't remove it from the DOM on page change if it's
                              removed here. So this is the best option I could find. */}
                                <meta content={disableCrawlers ? 'noindex' : ''} name="robots" />
                              </MetaTags>
                              <SWRConfig value={{ fetcher }}>
                                <RdmdStoreInitializer {...rdmd}>{children}</RdmdStoreInitializer>
                              </SWRConfig>
                            </LabsContext.Provider>
                          </EnterpriseParentContext.Provider>
                        </SelectedAppContext.Provider>
                      </AppMetaContext.Provider>
                    </UserContext.Provider>
                  </VersionContext.Provider>
                </ProjectState>
              </GlossaryContext.Provider>
            </VariablesContext.Provider>
          </LocalizationContext.Provider>
        </APIBaseUrlContext.Provider>
      </BaseUrlContext.Provider>
    </ConfigContext.Provider>
  );
};

AppContext.propTypes = {
  apiBaseUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  children: PropTypes.any,
  config: PropTypes.object,
  context: PropTypes.object,
  enterpriseLP: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // Ref propType: https://stackoverflow.com/a/51127130
  firstRun: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.bool })]),
  isDetachedProductionSite: PropTypes.bool,
  language: PropTypes.string,
  meta: PropTypes.object,
  // App name (i.e. wherein AppContext is being used)
  name: PropTypes.oneOf(['Dash', 'Hub', 'Playground']),
  rdmd: PropTypes.object,
};

export * from './types';
export default AppContext;
export {
  APIBaseUrlContext,
  AppMetaContext,
  BaseUrlContext,
  ConfigContext,
  EnterpriseParentContext,
  GlossaryContext,
  LocalizationContext,
  ProjectContext,
  UrlManager,
  UserContext,
  VersionContext,
  VariablesContext,
};
