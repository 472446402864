import type { ProjectPermission } from '../types';
import type { ProjectSchema } from '@readme/backend/models/project/types';
import type { PartialDeep } from 'type-fest';

import { typesToVerbage, groupTypesToVerbage } from '../types';
import { daysRemaining } from '../utils/date';

import { getProjectPermission } from './plans';

/**
 * Determines if a project's grace period is currently active. Returns `false`
 * to indicate that the grace period has expired.
 */
function isGracePeriodActive(project: PartialDeep<ProjectSchema>) {
  return !!project.gracePeriod?.enabled && daysRemaining(project.gracePeriod?.endsAt) > 0;
}

/**
 * Determines if a project's trial period is currently active. Returns `false`
 * to indicate that the trial period has expired.
 */
function isTrialPeriodActive(project: PartialDeep<ProjectSchema>) {
  return !!project.trial?.trialDeadlineEnabled && daysRemaining(project.trial?.trialEndsAt) > 0;
}

/**
 * Determines if the provided `project` is in an active trial state or not,
 * which includes more checks than simply evaluating trial periods. Use this as
 * the source of truth whether a project should have the "trial" experience
 * enabled or not.
 */
export function isTrialEnabled(project: PartialDeep<ProjectSchema>) {
  const { is_active: isActive = false, flags: { superHub } = {}, plan = '', planOverride = '' } = project;

  /** True if project is on a free-ish plan. */
  const isFreePlan = ['free', 'freelaunch'].includes(planOverride || plan);

  /**
   * For superhub projects, nix the requirement for trial periods. Otherwise,
   * continue to verify we are within either the trial or grace period window.
   */
  const inTrialWindow = superHub ? true : isTrialPeriodActive(project) || isGracePeriodActive(project);

  // To enable trial mode, the following must ALL be true:
  // - Project plan must be free
  // - Project must not be activated
  // - Project must be superhub or within trial or grace period window
  return isFreePlan && !isActive && inTrialWindow;
}

export function isCustomDomainAllowed(project: ProjectSchema) {
  // If project is trial and the grace period is not expired allow them to use custom domains. This is will be the state when projects transfer owners.
  const isTrial = project.plan === 'free';
  if (isTrial && isGracePeriodActive(project)) return true;

  return getProjectPermission(project, 'custom_domain');
}

export function getProjectBaseUrl(env: string, siteDomain: string, project: ProjectSchema, staging = false) {
  const isStagingEnabled = p => {
    const topLevelProject = p.childrenProjects?.length ? p : p._parent;
    if (!topLevelProject) return false;
    return topLevelProject.flags.staging;
  };

  if (env === 'development' && (!isStagingEnabled(project) || staging)) {
    return `http://${project.subdomain}.readme.local:3000/`;
  }

  const renderNotProd = new Set(['pr', 'next', 'stage']);

  const customDomain = project._parent ? project._parent.custom_domain : project.custom_domain;
  // go to custom domain
  if (renderNotProd.has(env) && isStagingEnabled(project) && !staging) {
    return `https://${customDomain}/`;
  }

  if (env === 'development' && !staging) {
    return `http://${customDomain}:3000/`;
  }

  if (customDomain && isCustomDomainAllowed(project) && !staging && !renderNotProd.has(env)) {
    return `https://${customDomain}/`;
  }

  return `https://${project.subdomain}.${siteDomain}/`;
}

export function getProjectHomeUrl(
  env: string,
  siteDomain: string,
  project: ProjectSchema,
  ...path: (boolean | string)[]
) {
  let baseUrl = getProjectBaseUrl(env, siteDomain, project);

  if (path.length === 1 && path[0] === false) {
    // eslint-disable-next-line no-param-reassign
    path = [];
  }

  // Child projects should have themselves
  // (either their subpath or their subdomain) in their home URL.
  if (project._parent && path[0] !== project.subpath && path[0] !== project.subdomain) {
    baseUrl = getProjectBaseUrl(env, siteDomain, project._parent);
    path.unshift(project.subpath || project.subdomain);
  }

  return baseUrl + path.join('/').replace(/^\//, '');
}

/** return the userType in either singular or plural */
export function fromUserType(userType: ProjectPermission['userType'], singular: boolean) {
  const toVerbage = singular ? typesToVerbage : groupTypesToVerbage;
  return toVerbage[userType];
}

export * from './permissions';
export * from './plans';
