/* eslint-disable no-param-reassign */
import flat from 'lodash/flattenDeep';
import isObject from 'lodash/isPlainObject';

interface DeepArray<T> extends Array<DeepArray<T> | T> {}

type StringMap = Record<string, string>;

interface ClassyConfig {
  bem?: string;
  classes?: StringMap;
  scope?: StringMap;
}

type ClassValue = DeepArray<string> | boolean | number | string | null | undefined;

export const SEPARATORS = ['-', '_'];

const splitClassStrings = (classes: string[]) =>
  flat(
    classes.map(c => {
      return typeof c === 'string' ? c?.split(/[\s,.]/g) : c;
    }),
  );

const expandBEMPartials = (classname: string, namespace: string) => {
  if (!(classname && namespace)) return classname;

  /* Replace Sass-style root selectors (&)
   * with the BEM namespace...
   */
  if (classname[0] === '&') return classname.replace('&', namespace);

  /* Prefix BEM separator "partials"
   * with the BEM namespace...
   */
  if (SEPARATORS.includes(classname[0])) {
    if (!classname.includes('-scss')) return `${namespace}${classname}`;
  }

  return classname;
};

const isScope = (scope: ClassValue | StringMap): scope is StringMap => isObject(scope);

export function classy(firstArg: ClassValue | StringMap, ...args: ClassValue[]): string {
  if (!firstArg && !args.length) return '';

  /* If the first param off the args array
   * is an object, we treat it as the CSS Module
   * hash which we'll to auto-scope our selectors!
   * Otherwise, add the initial arg back in to `classes`
   * and set the `scope` to an empty object
   */
  const scope = isScope(firstArg) ? firstArg : {};
  const classes = isScope(firstArg) ? args : [firstArg, ...args];

  /* Pluck the `bem` namespace out of the `scope`.
   */ const { bem = '' } = scope || {};

  /* Flatten nested arrays.
   */ const flatClasses = flat(classes as DeepArray<string>);

  /* Split stringy lists in to arrays.
   */ const splitClasses = splitClassStrings(flatClasses);

  return splitClasses
    .filter((cn: unknown) => typeof cn === 'string' && cn)
    .map((cn: string) => {
      /* BEM EXPANSIONS
       */ cn = expandBEMPartials(cn, bem);

      /* CSS MODULE AUTO-SCOPING
       */ if (scope && scope[cn]) cn = scope[cn];

      return cn;
    })
    .join(' ');
}

/* Construct an instance of Classy that
 * can be reused for its scope and BEM root!
 */
export function ClassyInstance(opts: ClassyConfig): (...selectors: ClassValue[]) => string {
  const { bem = '', classes = {}, scope: module = {} } = opts || {};
  const scope = { ...classes, ...module };
  return (...selectors: ClassValue[]) => {
    const cn = classy({ bem, ...scope }, ...selectors);
    return cn || scope?.[bem] || bem;
  };
}

export default classy;
