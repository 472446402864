import type { StoreApi } from 'zustand';

import { useStore } from 'zustand';
import { shallow } from 'zustand/shallow';

type ExtractState<S> = S extends { getState: () => infer T } ? T : never;

type BoundedStore = <S extends StoreApi<unknown>>(
  store: S,
) => {
  (): ExtractState<S>;
  <U>(selector: (state: ExtractState<S>) => U, equals?: (a: U, b: U) => boolean): U;
};

/**
 * Generates a bounded `useBoundedStore(selector?, equals?)` hook using the
 * provided vanilla store. The returned hook can then be called within React
 * components for easy access to the specific store bound to it. All types are
 * inferred. So all that's needed is to pass in the vanilla store instance 😎.
 *
 * Whenever defining and exporting a new vanilla store, call this function to
 * generate and export an equivalent React hook as well.
 *
 * @internal
 * @link https://docs.pmnd.rs/zustand/guides/typescript#bounded-usestore-hook-for-vanilla-stores
 * @example
 * export const useShoeStore = createBoundedUseStore(vanillaShoeStore);
 *
 * function MyComponent() {
 *   const shellTops = useShoeStore(state => state.shellTops);
 * }
 */
export const createBoundedUseStore = (store =>
  (selector, equals = shallow) =>
    useStore(store, selector as never, equals)) as BoundedStore;
