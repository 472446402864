import type { ToasterProps } from 'react-hot-toast';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';

import useEnvInfo from '@core/hooks/useEnvInfo';

const PortalToaster = (props: ToasterProps) => {
  const { isClient } = useEnvInfo();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Skip rendering for SSR
  if (!mounted || !isClient) return null;

  return ReactDOM.createPortal(<Toaster containerStyle={{ zIndex: 1000000 }} {...props} />, document.body);
};

export default PortalToaster;
