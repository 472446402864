import type { SuperHubStore } from '..';
import type {
  APIDefinitionUploadType,
  ReadAPIDefinitionCollectionType,
  ReadStagedAPIDefinitionType,
} from '@readme/api/src/mappings/apis/types';
import type { WritableDeep } from 'type-fest';
import type { StateCreator } from 'zustand';

import { mutate } from 'swr';

import type useReadmeApi from '@core/hooks/useReadmeApi';
import { fetcher } from '@core/hooks/useReadmeApi';
import { actionLog, isClient } from '@core/store/util';

interface SuperHubAPIDefinitionSliceState {
  /**
   * Collection of API definitions.
   */
  data: ReadAPIDefinitionCollectionType['data'] | null;

  /**
   * Indicates whether API Definitions is currently being loaded.
   */
  isLoading: boolean;

  /**
   * Indicates this slice has been initialized on both server and client. We use
   * this to differentiate how the state should update during SSR vs CSR. On the
   * server, the state should never prevent updates or retain previously
   * existing data. This is only safe to do once this flag is flipped to `true`.
   * @see initialize
   */
  isReady: boolean;

  /**
   * Holds reference to the SWR request key that was used to fetch document
   * data from the API. This key can then be used by SWR's `mutate()` function
   * to update cached data to optimistically reflect a changed document while
   * a mutation request is in flight.
   * @see https://swr.vercel.app/docs/mutation
   */
  swrKey?: ReturnType<typeof useReadmeApi>['swrKey'];
}

interface SuperHubAPIDefinitionSliceAction {
  /**
   * Create a new API definition based on the provided schema.
   */
  createDefinition: (payload: APIDefinitionUploadType) => Promise<ReadStagedAPIDefinitionType>;

  /**
   * Delete the specified API definition.
   */
  deleteDefinition: (filename: string) => Promise<void>;

  /**
   * Converts the API definitions `data` array into a collection representation
   * that matches the response type from the SWR connection to `/apis`.
   * Primarily used when calling `mutate()` and having to update the SWR cache
   * with the same response type.
   * @see ConnectSuperHubApiDefinitions
   */
  getCollectionData: () => ReadAPIDefinitionCollectionType;

  /**
   * Updates the state with data and loading states. Typically called when
   * initializing this slice with SSR data or when hydrating it from an API.
   */
  initialize: (payload: Pick<SuperHubAPIDefinitionSliceState, 'data' | 'isLoading' | 'swrKey'>) => void;

  /**
   * Replace an existing API definition with a new schema.
   */
  replaceDefinition: (filename: string, payload: APIDefinitionUploadType) => Promise<ReadStagedAPIDefinitionType>;
}

export interface SuperHubAPIDefinitionSlice {
  /**
   * State slice containing fields and actions that are relevant when viewing
   * and editing API definitions in SuperHub.
   */
  apiDefinitions: SuperHubAPIDefinitionSliceAction & SuperHubAPIDefinitionSliceState;
}

const initialState: SuperHubAPIDefinitionSliceState = {
  data: null,
  isLoading: false,
  isReady: false,
  swrKey: null,
};

/**
 * Creates a state slice containing all fields related to API definitions.
 */
export const createSuperHubAPIDefinitionSlice: StateCreator<
  SuperHubAPIDefinitionSlice & SuperHubStore,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  SuperHubAPIDefinitionSlice
> = (set, get) => ({
  apiDefinitions: {
    ...initialState,

    createDefinition: async payload => {
      set(() => {}, false, actionLog('apiDefinition.createDefinition', payload));

      // Convert payload into multipart form data to send as our request body.
      const formData = Object.entries(payload).reduce((data, [key, value]) => {
        data.set(key, value);
        return data;
      }, new FormData());

      const request = fetcher<ReadStagedAPIDefinitionType>(`${get().apiBaseUrl}/apis`, {
        body: formData,
        method: 'POST',
        isFormData: true,
      });

      mutate<ReadAPIDefinitionCollectionType>(
        get().apiDefinitions.swrKey,
        request.then(() => {
          // Revalidate sidebar to rehydrate with updated definitions.
          get().sidebar.revalidate();
          return get().apiDefinitions.getCollectionData();
        }),
        { revalidate: true },
      );

      return request;
    },

    deleteDefinition: async filename => {
      set(
        state => {
          if (!state.apiDefinitions.data) return;

          const index = state.apiDefinitions.data.findIndex(d => filename === d.filename);
          if (index >= 0) {
            state.apiDefinitions.data.splice(index, 1);
          }
        },
        false,
        actionLog('apiDefinition.deleteDefinition', filename),
      );

      const nextCollection = get().apiDefinitions.getCollectionData();
      const request = fetcher<void>(`${get().apiBaseUrl}/apis/${filename}`, {
        method: 'DELETE',
      });

      mutate<ReadAPIDefinitionCollectionType>(
        get().apiDefinitions.swrKey,
        request.then(() => {
          // Revalidate sidebar to rehydrate with updated definitions.
          get().sidebar.revalidate();
          return nextCollection;
        }),
        {
          optimisticData: nextCollection,
          revalidate: false,
        },
      );

      return request;
    },

    getCollectionData: () => {
      const data = get().apiDefinitions.data || [];
      return {
        data,
        total: data.length,
      };
    },

    initialize: payload => {
      const { data, isLoading, swrKey } = payload;
      const nextData = get().apiDefinitions.isReady
        ? {
            data: data ?? get().apiDefinitions.data,
            isLoading,
          }
        : { data, isLoading };

      // Only continue with a state update if there are changed values. This
      // quiets down the redux devtools action logs to only contain actions
      // that contain differences.
      const hasChanges = Object.entries(nextData).some(([key, value]) => {
        return JSON.stringify(value) !== JSON.stringify(get().apiDefinitions[key]);
      });
      if (!hasChanges) return;

      set(
        state => {
          const writableSwrKey = (swrKey ?? get().apiDefinitions.swrKey) as WritableDeep<typeof swrKey>;
          state.apiDefinitions = {
            ...state.apiDefinitions,
            ...nextData,
            swrKey: writableSwrKey ?? null,
          };

          // When running on the server, we must avoid marking this store as
          // "ready" to ensure it continues receiving updates until it gets
          // initialized on the client's first render.
          state.apiDefinitions.isReady = isClient;
        },
        false,
        actionLog('apiDefinition.initialize', payload),
      );
    },

    replaceDefinition: async (filename, payload) => {
      set(() => {}, false, actionLog('apiDefinition.replaceDefinition', { filename, payload }));

      // Convert payload into multipart form data to send as our request body.
      const formData = Object.entries(payload).reduce((data, [key, value]) => {
        data.set(key, value);
        return data;
      }, new FormData());

      const request = fetcher<ReadStagedAPIDefinitionType>(`${get().apiBaseUrl}/apis/${filename}`, {
        body: formData,
        method: 'PUT',
        isFormData: true,
      });

      mutate<ReadAPIDefinitionCollectionType>(
        get().apiDefinitions.swrKey,
        request.then(() => {
          // Revalidate sidebar to rehydrate with updated definitions.
          get().sidebar.revalidate();
          return get().apiDefinitions.getCollectionData();
        }),
        { revalidate: true },
      );

      return request;
    },
  },
});

export * from './ConnectSuperHubApiDefinitions';
