import type { ProjectSchema } from '@readme/backend/models/project/types';
import type { BareFetcher, Key, SWRConfiguration } from 'swr';

import { useCallback, useContext } from 'react';
import useSWR from 'swr';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useTimezone from '@core/hooks/useTimezone';

import fetcher from './fetcher';

export interface FetcherResponse<T> {
  headers: Record<string, string>;
  response: T;
}

export interface FetcherOptions {
  apiKey?: string;
  body?: object;
  headers?: Record<string, string>;
  host: string;
  method: string;
  path: string;
}

export function useMetricsAPIFetcher<T>({ version }: { version: string } = { version: '' }) {
  const { metrics: { dashUrl = '' } = {}, name } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext);
  // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
  const { apiKey, subdomain } = project as ProjectSchema;
  const isHub = name === 'Hub';

  const timezone = useTimezone() || 'UTC';

  const fetchCallback = useCallback(
    async ({
      path,
      method = 'GET',
      body,
      headers,
    }: {
      body?: object;
      headers?: Record<string, string>;
      method?: string;
      path: string;
    }) => {
      const hubRoute = version
        ? `/${subdomain}/api-next/v2/versions/${version}/metrics-proxy`
        : `/${subdomain}/api-next/v2/metrics-proxy`;

      const { response, headers: responseHeaders } = await fetcher<T>({
        host: isHub ? hubRoute : `${dashUrl}/v3`,
        path,
        headers: { ...headers, 'x-timezone': timezone },
        apiKey,
        body,
        method,
      });

      return { response, headers: responseHeaders };
    },
    [apiKey, dashUrl, isHub, subdomain, timezone, version],
  );

  return fetchCallback;
}

/**
 * Base Metrics API fetcher hook
 */
export function useMetricsAPI<Data>(path: string, isReady = true, options: Partial<FetcherOptions> = {}) {
  const { name, metrics: { dashUrl = '' } = {} } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext);
  // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
  const { apiKey, subdomain } = project as ProjectSchema;
  const isHub = name === 'Hub';
  const isDash = name === 'Dash';

  let timezone = useTimezone();

  let isUTCFallback = false;

  // If timezone doesn't resolve for some reason, we don't want requests to fail
  // We'll add a custom fallback to UTC in this scenario
  if (!timezone) {
    timezone = 'UTC';
    isUTCFallback = true;
  }

  const swrOptions: SWRConfiguration = {
    revalidateOnMount: true,
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  };

  const config: Key = {
    host: isHub ? `/${subdomain}/api-next/v2/metrics-proxy` : `${dashUrl}/v3`,
    path,
    apiKey: isDash ? apiKey : undefined,
    ...options,
    // Always include x-timezone in headers for all Metrics requests
    headers: {
      ...options.headers,
      'x-timezone': timezone,
    },
  };

  const isReadyToFetch = isReady && (timezone !== 'UTC' || isUTCFallback);

  /**
   * We cast the fetcher to BareFetcher<Data> here because we're using a generic type
   * and returning a type that includes the response headers
   * */
  const { data, error, isValidating } = useSWR<Data>(
    isReadyToFetch ? config : null,
    fetcher as BareFetcher<Data>,
    swrOptions,
  );

  const { response, headers } = (data || {}) as FetcherResponse<Data>;

  return {
    data: response,
    headers,
    error,
    isLoading: !error && !response,
    isValidating,
  };
}

export default useMetricsAPI;
