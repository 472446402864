import { createEncryptedKey, type $TSFixMe } from '@readme/iso';
import AES from 'crypto-js/aes';
import CryptoJS from 'crypto-js/core';
import Cookie from 'js-cookie';
import { useCallback, useContext, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { ConfigContextValue, ProjectContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useEventListener from '@core/hooks/useEventListener';

import useEnvInfo from '../useEnvInfo';

/**
 * This is a slim wrapper for the localStorage API that will automatically prefix any stored data
 * under a `@readme` namespace but can also handle encrypting data into localStorage with AES
 * encryption and a stored cookie secret that the user has.
 */
export default function useLocalStorage(
  opts: {
    encrypt?: boolean;
    json?: boolean;
    /**
     * An object containing a handler function and optional key. Whenever the browser fires matching
     * `StorageEvent` events and key of the event matches the current `key`, the `handler` function fires.
     * The `oldValue` and `newValue` parameters are automatically decrypted and/or parsed.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/StorageEvent}
     */
    onChange?: {
      handler: (opts: {
        key: string;
        newValue: $TSFixMe[] | object | string | null;
        oldValue: $TSFixMe[] | object | string | null;
      }) => void;
      key?: string;
    };
    prefix?: string;
  } = { encrypt: false, json: false },
) {
  const { isClient, isTest } = useEnvInfo();
  const { encryptedLocalStorageKey } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const aesKey = useMemo(() => {
    if (!opts.encrypt) {
      return '';
    }

    let cookieval = Cookie.get(encryptedLocalStorageKey);
    // the third condition is a security check to ensure
    // that the encryption key is for the current project
    if (cookieval && typeof cookieval === 'string' && cookieval.startsWith?.(createEncryptedKey(project, ''))) {
      return cookieval;
    }

    cookieval = createEncryptedKey(project, uuid());

    Cookie.set(encryptedLocalStorageKey, cookieval, {
      expires:
        // If the project's JWT expiration time is less than a day, use that value.
        // Otherwise, set the cookie expiration to 1 day.
        project?.jwtExpirationTime && project.jwtExpirationTime < 1440 ? project.jwtExpirationTime / 1440 : 1,
      // The `secure` flag in a cookie is only functional in HTTPS or localhost environments, so if
      // we set `secure: true` on a cookie in our local http://readme.local, this cookie creation call
      // will silently do nothing.
      secure: isClient && window.location.protocol === 'https:',
      sameSite: 'strict',
    });

    return cookieval;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encryptedLocalStorageKey, isClient, opts.encrypt, project._id]);

  const localStorage = useMemo(() => {
    return typeof window !== 'undefined'
      ? window.localStorage
      : (() => {
          const storage = new Map();
          return {
            getItem: (key: string) => {
              return storage.has(key) ? storage.get(key) : null;
            },

            setItem: (key: string, val: string) => {
              storage.set(key, val);
            },

            removeItem: (key: string) => {
              storage.delete(key);
            },

            clear: () => {
              storage.clear();
            },
          };
        })();
  }, []);

  const decryptAndOrParseData = useCallback(
    (key: string, data: $TSFixMe) => {
      let returnData = data;
      if (data && opts.encrypt) {
        try {
          returnData = AES.decrypt(returnData, aesKey).toString(CryptoJS.enc.Utf8);
        } catch (e) {
          if (!isTest) {
            // eslint-disable-next-line no-console
            console.error(`Something went wrong decrypting localStorage data for #${key}.`);
          }
          returnData = null;
        }
      }

      if (returnData && opts.json) {
        try {
          returnData = JSON.parse(returnData);
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(`Couldn't parse local JSON data for #${key}.`, returnData);
        }
      }

      return returnData;
    },
    [aesKey, isTest, opts.encrypt, opts.json],
  );

  const prepareKey = useCallback(
    (key: string) => {
      let prepared = '@readme';
      if (opts.prefix) {
        prepared += `/${opts.prefix}`;
      }

      return `${prepared}:${key}`;
    },
    [opts.prefix],
  );

  const onEvent = useCallback(
    (event: StorageEvent) => {
      if (opts.onChange) {
        if (
          event.key &&
          // if there's a key option passed, match against it,
          // otherwise check that the event key partially matches the namespace
          (opts.onChange.key ? event.key === prepareKey(opts.onChange.key) : event.key?.startsWith(prepareKey('')))
        ) {
          opts.onChange.handler({
            key: event.key,
            newValue: decryptAndOrParseData(event.key, event.newValue),
            oldValue: decryptAndOrParseData(event.key, event.oldValue),
          });
        }
      }
    },
    [decryptAndOrParseData, opts.onChange, prepareKey],
  );

  const setItem = useCallback(
    (itemKey: string, itemData: string) => {
      // When in a test environment, we need to manually dispatch events
      // since this event only fires when a different window/tab modifies local storage.
      // more info: https://stackoverflow.com/a/65348883
      if (isTest) {
        window.dispatchEvent(
          new StorageEvent('storage', {
            key: itemKey,
            oldValue: localStorage.getItem(itemKey),
            newValue: itemData,
          }),
        );
      }

      localStorage.setItem(itemKey, itemData);
    },
    [isTest, localStorage],
  );

  useEventListener('storage', onEvent);

  return useMemo(() => {
    return {
      setItem: (key: string, data: unknown): void => {
        const itemKey = prepareKey(key);
        let itemData = data as string;

        if (opts.json) {
          try {
            itemData = JSON.stringify(data);
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error(`Couldn't stringify local JSON data for #${itemKey}.`, data);
          }
        }

        if (opts.encrypt) {
          itemData = AES.encrypt(itemData, aesKey).toString();
        }

        setItem(itemKey, itemData);
      },

      getItem: (key: string) => {
        const data = localStorage.getItem(prepareKey(key));

        return decryptAndOrParseData(key, data);
      },

      removeItem: (key: string): void => {
        localStorage.removeItem(prepareKey(key));
      },

      clear: () => {
        localStorage.clear();
      },
    };
  }, [aesKey, decryptAndOrParseData, localStorage, opts.encrypt, opts.json, prepareKey, setItem]);
}
