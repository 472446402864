import React from 'react';

import classy from '@core/utils/classy';

import './style.scss';

export interface SpinnerProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xxl';
  strokeWidth?: number;
}

export default function Spinner({ size, strokeWidth = 2, className = '', ...props }: SpinnerProps) {
  return (
    <div className={classy('Spinner', size && `Spinner_${size}`, className)} role="progressbar">
      <svg className="Spinner-icon" viewBox="0 0 16 16" {...props}>
        <title>Loading</title>
        <path
          d="M15 8a7 7 0 10-7 7"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={strokeWidth}
          vectorEffect="non-scaling-stroke"
        />
      </svg>
      {/* Will fall back to label when user prefers reduced motion */}
      <span className="Spinner-label">Loading…</span>
    </div>
  );
}
