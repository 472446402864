import type { Options } from '.';

import { useContext } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';

import useReadmeApi from '.';

/**
 * A wrapper around `useReadmeApi` that prefixes the provided path with the
 * required base url path params for api-next (aka api v2) endpoints.
 */
function useReadmeApiNext<Data>(path: string | null, options: Options = {}, isReady = true) {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { subdomain } = project;
  return useReadmeApi<Data>(path, { ...options, apiBaseUrl: `/${subdomain}/api-next/v2` }, isReady);
}

export default useReadmeApiNext;
