/**
 * Helper function to configure the Redux DevTools action object with the
 * corresponding action `type` and `payload`. Use the action's function name as
 * `type` and the action's argument as the `payload`. Invoke this function as
 * the last argument to the store's `set(...)` function.
 *
 * @example
 * updateColor(value) {
 *   set(state => {...}, false, actionLog('updateColor', value));
 * }
 */
export const actionLog = (type: string, payload?: unknown) => ({ type, payload });

/**
 * Indicates whether our environment is running on the client browser or not.
 */
export const isClient = typeof window !== 'undefined';

export * from './createBoundedUseStore';
export * from './RenderStoreData';
