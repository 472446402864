import type { DebounceSettings } from 'lodash'; // eslint-disable-line no-restricted-imports

import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

type DebounceParams = Parameters<typeof debounce>;

/**
 * Debounces a callback function.
 */
const useDebounced = <T extends DebounceParams[0]>(handler: T, delay = 0, opts?: DebounceSettings) => {
  // Debounce the raw handler method.
  const debounced = useMemo(() => debounce(handler, delay, opts), [delay, handler, opts]);

  // Cancel pending invocations on unmount.
  useEffect(() => () => debounced.cancel(), [debounced]);

  return debounced;
};

export default useDebounced;
