import React, { useEffect } from 'react';

import { useMetricsStore } from '..';

interface InitializeMyDevelopersProps {
  children: React.ReactNode;
}

/**
 * Initializes the MetricStore's myDevelopers slice w/ date range info pulled from MetricsStore's query
 *  and marks it as ready. Use this to initially hydrate the store just once.
 *
 * @example
 * <InitializeMyDevelopers >
 *   ...
 * </InitializeMyDevelopers>
 */
export function InitializeMyDevelopers({ children }: InitializeMyDevelopersProps) {
  const [initialize, isReady] = useMetricsStore(s => [s.myDevelopers.initialize, s.myDevelopers.isReady]);

  // Initialize slice if not ready
  // This will only run once and sets up the active filters using the MetricsStore's query filters
  useEffect(() => {
    if (!isReady) {
      initialize();
    }
  }, [initialize, isReady]);

  return <>{children}</>;
}
