import debug from 'debug';

const debugJSON = debug('readme:json');

/**
 * Helper utility that wraps JSON.parse in try/catch
 * @param value
 * @returns object | null
 */
export const safelyParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    debugJSON('Something went wrong parsing JSON! %s', e.stack || e.message || '');
    return null;
  }
};

/**
 * Helper utility that wraps JSON.stringify in try/catch
 * @param value
 * @returns string | null
 */
export const safelyStringifyJSON = (value: unknown) => {
  try {
    return JSON.stringify(value);
  } catch (e) {
    debugJSON('Something went wrong stringifying JSON! %s', e.stack || e.message || '');
    return null;
  }
};
