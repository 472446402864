import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import { useSuperHubStore } from '..';

export interface InitializeSuperHubSidebarProps {
  children: React.ReactNode;

  /**
   * Sidebar data that is typically sourced from route data or fetched directly
   * from the v2 API endpoint.
   */
  sidebar?: GitSidebarCategory[];
}

/**
 * Initializes the SuperHubStore's sidebar slice with initial sidebar data and.
 * Use this to hydrate the store from SSR props which in turn initializes the
 * store both during both CSR and SSR of our store and connected components.
 * @example
 * <InitializeSuperHubSidebar sidebar={ssrProps.sidebar}>
 *   ...
 * </InitializeSuperHubSidebar>
 */
export function InitializeSuperHubSidebar({ children, sidebar = [] }: InitializeSuperHubSidebarProps) {
  const { isServer } = useEnvInfo();
  const [initialize, isReady] = useSuperHubStore(s => [s.sidebar.initialize, s.sidebar.isReady]);

  // To enable SSR, we must call this inline rather than inside an effect. On
  // the server, ignore the "ready" state b/c our global stores on the server
  // persist indefinitely and won't ever reset the "ready" state to false.
  if (isServer || !isReady) {
    initialize({
      data: sidebar,
      error: null,
      isLoading: false,
    });
  }

  return <>{children}</>;
}
