export enum UserPermission {
  Admin = 'admin',
  None = '',
  ReadOnly = 'readonly',
}

export interface ProjectPermission {
  fromGroup?: boolean;
  groupName?: string;
  project: any;
  userType: TUserPermission;
}

// This type allows consumers to pass in the literal string value of the enum,
// mostly to support legacy code
export const TUserPermission = {
  Admin: 'admin',
  None: '',
  ReadOnly: 'readonly',
} as const;
export type TUserPermission = (typeof TUserPermission)[keyof typeof TUserPermission] | UserPermission;

export const verbageToTypes: Record<string, ProjectPermission['userType']> = {
  'No Access': '',
  'Group Viewer': 'readonly',
  Viewer: 'readonly',
  'Group Admin': 'admin',
  Admin: 'admin',
};

export const groupTypesToVerbage = {
  [TUserPermission.None]: 'No Access',
  [TUserPermission.ReadOnly]: 'Group Viewer',
  [TUserPermission.Admin]: 'Group Admin',
};

export const typesToVerbage = {
  [TUserPermission.None]: 'No Access',
  [TUserPermission.ReadOnly]: 'Viewer',
  [TUserPermission.Admin]: 'Admin',
};

export enum LoginType {
  CUSTOM_JWT = 'custom-jwt',
  OAUTH = 'oauth',
  README = 'readme',
  SAML = 'saml',
}
