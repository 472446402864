import type { HarRequest } from '@readme/oas-to-snippet/types';

import React, { useState, useCallback } from 'react';

export const EphemeralHARContext = React.createContext<{
  clearEphemeralHAR: () => void;
  ephemeralHAR?: HarRequest | null;
  setEphemeralHAR: (setValue: HarRequest | null) => void;
}>({
  clearEphemeralHAR: () => {},
  ephemeralHAR: null,
  setEphemeralHAR: () => {},
});
export const MockHarContext = React.createContext<{
  addHarOverride: (nextHar: unknown) => void;
  clearMockHars: () => void;
  mockHars: unknown[];
  setShouldUseMockHars: (setValue: boolean) => void;
  shouldUseMockHars: boolean;
}>({
  addHarOverride: () => {},
  clearMockHars: () => {},
  mockHars: [],
  setShouldUseMockHars: () => {},
  shouldUseMockHars: false,
});

/**
 * When an operation has no auth, we're not able to send and retrieve metrics to our server, so instead we push the HARs
 * into this state to temporarily mock persistence on the client-side in between refreshes.
 *
 * @link https://linear.app/readme-io/issue/RM-1362
 * @returns Object
 */
function useMockHarContext() {
  const [shouldUseMockHars, setShouldUseMockHars] = useState(false);
  const [mockHars, setMockHars] = useState<unknown[]>([]);

  const addHarOverride = useCallback(nextHar => {
    // Put the next har at the beginning of the list as it should be ordered reverse chronologically
    if (nextHar) setMockHars(hars => [nextHar, ...hars]);
  }, []);

  const clearMockHars = useCallback(() => {
    if (shouldUseMockHars) setMockHars([]);
  }, [shouldUseMockHars]);

  return { shouldUseMockHars, setShouldUseMockHars, mockHars, addHarOverride, clearMockHars };
}

function useEphemeralHARContext() {
  const [ephemeralHAR, setEphemeralHAR] = useState<HarRequest | null>(null);

  const clearEphemeralHAR = useCallback(() => {
    if (ephemeralHAR) {
      setEphemeralHAR(null);
    }
  }, [ephemeralHAR]);

  return { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR };
}

function HARContext({ children }) {
  const MockHarContextValue = useMockHarContext();
  const EphemeralHARContextValue = useEphemeralHARContext();

  return (
    <MockHarContext.Provider value={MockHarContextValue}>
      <EphemeralHARContext.Provider value={EphemeralHARContextValue}>{children}</EphemeralHARContext.Provider>
    </MockHarContext.Provider>
  );
}

export default HARContext;
